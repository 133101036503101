import React from 'react';
import './Terms.css';

class Terms extends React.Component {
    render() {
        return (
            <div className="terms-container">
                <h1 className="terms-header">Terms and Conditions</h1>
                <p className="terms-text">
               <h2>Last modified: July 6, 2023</h2>
               <br></br>
<p>
Welcome to AskAGuru Corp. ("AskAGuru") website — https://AskAGuru.com — and any and all products, services, applications, platforms, websites, or other affiliated ventures offered, controlled, owned or operated by AskAGuru (the "AskAGuru Platform"). The following Terms of Use for the AskAGuru Platform creates a legal contract between you, either an individual customer, member or user, or a single entity, and AskAGuru regarding your use of the AskAGuru Platform. Throughout these Terms of Use, "AskAGuru" and "We" refers to AskAGuru Corp. and its affiliates. "You" or "Users" refers to you, our users — everyone who uses, subscribes to, publishes through, joins, or visits AskAGuru.
</p>
<br></br>
<p>
BEFORE USING THE AskAGuru PLATFORM, PLEASE READ CAREFULLY THE FOLLOWING CONDITIONS AND TERMS OF USE. BY REGISTERING FOR AND/OR ACCESSING, BROWSING, AND USING THE AskAGuru PLATFORM, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THE FOLLOWING TERMS, INCLUDING ANY ADDITIONAL GUIDELINES, AND ANY FUTURE MODIFICATIONS (COLLECTIVELY, THE "TERMS"), AND THAT YOUR USE OF THE AskAGuru PLATFORM WILL COMPLY WITH ANY AND ALL APPLICABLE LAWS AND REGULATIONS AT ALL TIMES DURING YOUR USE OF THE AskAGuru PLATFORM. IF AT ANY TIME YOU DO NOT AGREE TO THESE TERMS, PLEASE IMMEDIATELY TERMINATE YOUR USE OF THE AskAGuru PLATFORM.
</p>
<br></br>
<p>
NOTE TO CHILDREN UNDER 13 YEARS OF AGE: THE AskAGuru PLATFORM IS NOT FOR PERSONS UNDER THE AGE OF 13. If you are under 13 years of age, then please do not use the AskAGuru Platform.
</p>
<br></br>
<p>
You may receive a copy of these Terms of Use by e-mailing us at Contact@AskAGuru.com and identifying the subject line of the e-mail as "Terms of Use."
</p>
<br></br>
<p>
<b>1. Eligibility</b>
</p>
<br></br>
You represent that you are 18 years of age or older and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations and warranties set forth in these Terms and to abide by and comply with these Terms. In any case, you affirm that you are over the age of 13, as the AskAGuru Platform is not intended for children under the age of 13. If you are using or opening an account on the AskAGuru Platform on behalf of a company, entity, or organization (collectively "Subscribing Organization"), then you represent and warrant that you: (1) are an authorized representative of that Subscribing Organization with the authority to bind such organization to these Terms and (2) agree to be bound by these Terms on behalf of such Subscribing Organization.
<p>
<br></br>
<b>2. Privacy policy</b>
</p>
<br></br>
<p>
AskAGuru understands that privacy is important. By using the AskAGuru Platform, you expressly consent to the information handling practices described in the AskAGuru Privacy Policy (https://AskAGuru.com/privacy-policy), which is incorporated into and is subject to the Terms. Your use of the AskAGuru Platform and any personal information you provide through the AskAGuru Platform are subject at all times to the Privacy Policy and the Terms.
</p>
<br></br>
<p>
<b>3. Individual Features and Services</b>
</p>
<br></br>
<p>
When using the AskAGuru Platform, you will be subject to any additional posted guidelines or rules applicable to specific services, and features which may be posted from time to time (the "Guidelines"). All such Guidelines are hereby incorporated by reference into the Terms.
</p>
<br></br>
<p>
<b>4. Modification of the Terms</b>
</p>
<br></br>
<p>
AskAGuru reserves the right, at its discretion, to change, modify, add, or remove portions of these Terms at any time and from time to time with or without specific notice to you other than through posting such modified Terms on the AskAGuru Platform. The Terms will be identified as of the most recent date of revision. Notwithstanding the preceding sentences, no modifications to these Terms will apply to any dispute between you and AskAGuru that arose prior to the date of such modification. For the avoidance of doubt, the foregoing limitation will not apply to changes made to these Terms necessary to ensure continued compliance with any statutory licenses contained in the United States Copyright Act. Please check these Terms and any Guidelines periodically for changes. Your continued use of the AskAGuru Platform after the posting of changes constitutes your binding acceptance of such changes. If you are dissatisfied with the AskAGuru Platform, the content thereon, or the Terms or the Privacy Policy, then you agree that your sole and exclusive remedy is to discontinue any use of the AskAGuru Platform.
</p>
<br></br>
<p>
<b>5. AskAGuru Platform Access</b>
</p>
<br></br>
<p>
AskAGuru grants you permission to use the AskAGuru Platform as set forth in these Terms, provided that: (1) you use the AskAGuru Platform solely for your personal, private, noncommercial use; (2) you do not copy, publicly display or distribute any part of the AskAGuru Platform in any medium without AskAGuru' prior written authorization; (3) you do not alter or modify any part of the AskAGuru Platform other than as may be reasonably necessary to use the AskAGuru Platform for its intended purposes; (4) you do not engage in any of the prohibited uses described below; and (5) you will otherwise fully comply with these Terms. The AskAGuru Platform is controlled and offered by AskAGuru from its facilities in the United States of America. AskAGuru makes no representations that the AskAGuru Platform is appropriate or available for use in other locations. Those who access or use the AskAGuru Platform from other jurisdictions do so at their own risk and are responsible for compliance with local law.
</p>
<br></br>
<p>
<b>6. Ownership; Proprietary Rights</b>
</p>
<br></br>
<p>
The AskAGuru Platform is owned and operated by AskAGuru Corp. The content, visual interfaces, interactive features, information, graphics, design, compilation, computer code, products, software, services, and all other elements of the AskAGuru Platform that are provided by AskAGuru ("AskAGuru Materials") are protected by United States copyright, trade dress, patent, and trademark laws, international conventions, and all other relevant intellectual property and proprietary rights, and applicable laws. AskAGuru Materials do not include User Submissions (defined below) or any other content owned by and submitted by Users to the AskAGuru Platform. All AskAGuru Materials contained on the AskAGuru Platform are the property of AskAGuru or its subsidiaries or affiliated companies and/or third-party licensors. All trademarks, service marks, and trade names contained in the AskAGuru Materials or on the AskAGuru Platform are proprietary to AskAGuru or its affiliates and/or third-party licensors. Except as expressly authorized by AskAGuru, you agree not to sell, license, distribute, copy, modify, publicly perform or display, transmit, publish, edit, adapt, create derivative works from, or otherwise make unauthorized use of the AskAGuru Materials. AskAGuru reserves all rights not expressly granted in these Terms. You shall not acquire any right, title or interest to the AskAGuru Materials, except for the limited rights set forth in these Terms.
</p>
<br></br>
<p>
<b>7. Audio Content </b>
</p>
<br></br>
<p>
7.1 Generally. AskAGuru allows you to create a mix of sound recordings that have been released to the public under the authority of the copyright owners of the sound recordings (the "Audio Content") and then have that Audio Content streamed through the AskAGuru Platform (the "Audio Content").You hereby represent and warrant that you have the necessary licenses, rights, consents, and permissions to use and authorize AskAGuru to reproduce, publicly perform, and otherwise use Audio Content uploaded by you in the manner contemplated by AskAGuru and these Terms. You further represent and warrant that you have taken no steps, either directly or indirectly, to intentionally defeat any technological measures implemented by AskAGuru to ensure the compliance with any laws or regulations, including, but not limited to, limitations set forth in Sections 112 and 114 of the United States Copyright Act.
</p>
<br></br>
<p>
7.2 Audio Content Representations. You hereby represent and warrant that when using the AskAGuru Platform for audio streaming, you will not interfere with the streaming mechanism of the AskAGuru Platform, including in any way that interferes with the ability of the AskAGuru Platform to comply with 17 USC §§ 112 and 114, the regulations adopted pursuant thereto or other provisions of the copyright laws of the United States or any other applicable laws and regulations of jurisdictions in which you may be resident. You further represent and warrant that any Audio Content uploaded by you onto the AskAGuru Platform (a) will include the complete and correct title of the sound recording (i.e., song name), featured recording artist name, and title of the album released under the authority of the copyright owner; (b) you have not and will not misidentify any metadata for any Audio Content uploaded by you or any other person to the AskAGuru Platform; (c) any Audio Content uploaded by you to the AskAGuru Platform is from a lawfully obtained phonorecord and not from any illegally acquired content, including any content downloaded from unauthorized or illegal peer-to-peer file sharing services; and (d) the Audio Content uploaded to the AskAGuru Platform has been publicly released in the United States under the consent of the copyright owner of the Audio Content (e.g., the Audio Content is not from a bootleg recording or unauthorized recording made available before the Audio Content's "street date"). Furthermore you are bound to whatever terms and conditions are stipulated by any 3rd party streaming services we may use within the platform.
</p>
<br></br>
<p>
<b>8. User Submissions. </b>
</p>
<br></br>
<p>
8.1 General. The AskAGuru Platform may now or in the future permit the submission and posting or linking of media, commentary, user names, or any other content (including Audio Content) submitted by you and other users ("User Submissions"), and the hosting, sharing, and/or publishing of such User Submissions. User Submissions are displayed for entertainment and informational purposes only and are not controlled by AskAGuru. AskAGuru makes no representations that it will publish or use your User Submissions in any way and may or may not use your User Submissions in its sole discretion. AskAGuru may remove any User Submissions from the AskAGuru Platform at any time in its sole discretion, with or without notice, including, but not limited to, any User Submissions that request, facilitate or support the unauthorized sharing of copyrighted material or the identification of the sound recordings comprising a mix. You understand that whether or not User Submissions are published, AskAGuru does not guarantee any confidentiality with respect to any User Submissions. In the event of a dispute between two or more Users with respect to any User Submissions, such as, by way of example and not limitation, the identification of any User name or mix name, AskAGuru reserves the right, in its sole discretion, to deny one or more users of the use of any name, including trademarked names, without liability to any Users and grant use rights to a single User.  If two or more Users claim the rights to any name, then AskAGuru may, in its sole discretion, suspend the use of such name until the parties agree between or among themselves as to the parties’ respective rights in and to such names or until such time as a court of competent jurisdiction, in a final, non-appealable decision, resolves the parties’ respective rights in and to such name.
</p>
<br></br>
<p>
8.2 Grant of Rights. You shall retain all of your ownership rights in your User Submissions. However, by submitting User Submissions to AskAGuru, you hereby grant AskAGuru and its affiliates a non-exclusive, fully paid-up, royalty-free, perpetual, irrevocable, sublicensable, and transferable license, throughout the universe, to use, reproduce, distribute, modify, adapt, prepare derivative works of, publicly display, publicly perform, and otherwise exploit your User Submissions in connection with the AskAGuru Platform and AskAGuru' (and its successor's) business, including, without limitation, for promoting and redistributing part or all of the AskAGuru Platform (and derivative works thereof) in any media formats and through any media channels now known or hereafter discovered. You hereby grant AskAGuru and its affiliates and sublicensees the right to use the name that you submit in connection with such User Submission if they choose to do so. You hereby irrevocably waive (and cause to be waived) any claims and assertions of moral rights or attribution with respect to your User Submissions. You also hereby grant to each user of the AskAGuru Platform a non-exclusive license to access your User Submissions through the AskAGuru Platform, and to use, reproduce, distribute, prepare derivative works of, display, and perform such User Submissions as permitted by the functionality of the AskAGuru Platform and these Terms.
</p>
<br></br>
<p>
8.3 User Submissions Representations and Warranties. You shall be solely responsible for your own User Submissions and the consequences of posting or publishing them. In connection with User Submissions, you affirm, represent, and/or warrant that: (a) you own, or have the necessary licenses, rights, consents, and permissions to use and authorize AskAGuru to use all patent, trademark, copyright, or other proprietary rights in and to any and all User Submissions to enable inclusion and use of User Submissions in the manner contemplated by AskAGuru and these Terms, and to grant the rights and license set forth above; and (b) your User Submissions, AskAGuru' use of such User Submissions pursuant to these Terms , and AskAGuru' exercise of the license rights set forth above, do not and will not: (i) infringe, violate, or misappropriate any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (ii) slander, defame, libel, or invade the right of privacy, publicity or other property rights of any other person; or (iii) violate any applicable law or regulation.
</p>
<br></br>
<p>
8.4 User Submissions Prohibited Uses. In connection with your User Submissions, you further agree that you will not: (a) publish falsehoods or misrepresentations that could damage AskAGuru or any third party; (b) intentionally misidentify the identifying information of User Submissions; (c) submit material that is unlawful, defamatory, libelous, slanderous, threatening, pornographic, obscene, vulgar, harassing, harmful, hateful, abusive, racially or ethnically offensive or encourages conduct that would be considered a criminal offense, give rise to civil liability, violate any law or any right of privacy or publicity, or is otherwise inappropriate; (d) post advertisements or solicitations of business; (e) impersonate another person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity; or (f) solicit or engage in communications with other users for the unauthorized distribution and sharing of mixes or the individual sound recordings in any mix; or (g) post User Submissions that would be harmful to minors in any manner.
</p>
<br></br>
<p>
<b>9. Content Disclaimer </b>
</p>
<br></br>
<p>
You understand that when using the AskAGuru Platform, you will be exposed to User Submissions and other content (such User Submissions and other content, collectively, "Content") from a variety of sources, and that AskAGuru is not responsible for the accuracy, integrity, quality, legality, usefulness, safety, or intellectual property rights of or relating to such Content. You further understand and acknowledge that you may be exposed to Content that is inaccurate, offensive, indecent, or objectionable, and you agree to waive, and hereby do waive, any legal or equitable rights or remedies you have or may have against AskAGuru with respect thereto. AskAGuru does not endorse any User Submission or other Content or any opinion, recommendation, or advice expressed therein. Under no circumstances will AskAGuru be liable in any way for or in connection with any User Submissions or other Content, including, but not limited to, for any inaccuracies, errors or omissions in any Content, any intellectual property infringement with regard to any Content, or for any loss or damage of any kind incurred as a result of the use of any Content posted, electronically mailed or otherwise displayed or transmitted through the AskAGuru Platform.
</p>
<br></br>
<p>
<b>10. Monitoring Users and Content </b>
</p>
<br></br>
<p>
You understand that all Content is the sole responsibility of the person from whom such Content originated. This means that you, and not AskAGuru, are entirely responsible for all User Submissions that you upload, post, e-mail, transmit or otherwise make available through the AskAGuru Platform. AskAGuru does not control the Content posted by Users or otherwise made available by other persons and does not have any obligation to monitor such Content for any purpose. If at any time, AskAGuru chooses, in its sole discretion, to monitor Content, AskAGuru nonetheless assumes no responsibility for the Content, no obligation to modify or remove any inappropriate Content, and no responsibility for the conduct of the User submitting any such Content. You acknowledge that AskAGuru may or may not pre-screen User Submissions, but that AskAGuru and its designees shall have the right (but not the obligation) in their sole discretion to pre-screen, refuse, or remove any User Submission that is available via the AskAGuru Platform. Without limiting the foregoing, AskAGuru and its designees may, at any time and without prior notice, remove any User Submission that in the sole judgment of AskAGuru violates these Terms or is otherwise objectionable. You agree that you must evaluate, and bear all risks associated with the use of any User Submissions or other Content, including any reliance on the accuracy, completeness, usefulness or legality of such User Submission or other Content.
</p>
<br></br>
<p>
<b>11. Removal of Content </b>
</p>
<br></br>
<p>
AskAGuru and its designees shall have the right (but not the obligation) in their sole discretion to refuse or remove any Content that is available on the AskAGuru Platform, in whole or in part, at any time for any reason or no reason, with or without notice and with no liability of any kind.
</p>
<br></br>
<p>
<b>12. Prohibited Uses </b>
</p>
<br></br>
<p>
12.1 As a condition of your use of the AskAGuru Platform, you will not use the AskAGuru Platform for any purpose that is unlawful or prohibited by these Terms. Access to the AskAGuru Materials and the AskAGuru Platform from territories where their access or use thereof is illegal is strictly prohibited. AskAGuru Users are responsible for complying with all local rules, laws, and regulations including, without limitation, rules about intellectual property rights, the Internet, technology, data, electronic mail, or privacy.
</p>
<br></br>
<p>
12.2 Any use by you of any of the AskAGuru Materials and AskAGuru Platform other than for private, non-commercial use is strictly prohibited. You agree not to reproduce, duplicate, copy, sell, trade, resell, distribute or exploit any portion of the AskAGuru Platform, use of the AskAGuru Platform, access to the AskAGuru Platform, or Content obtained through the AskAGuru Platform, for any purpose other than for your personal, private, non-commercial purposes. You further agree not to engage in the unauthorized sharing of sound recordings with other users of the AskAGuru Platform, including, but not limited to, the sharing of some or all of the individual sound recordings embodied in any mixes available on the AskAGuru Platform, or the individual sound recordings comprising a mix.
</p>
<br></br>
<p>
12.3 Programming Limitations. By using the AskAGuru Platform, you agree to the following programming limitations, noncompliance with which will result in AskAGuru' removal of applicable mixes from public access and commencement of steps under its Repeat Infringer Policy, including terminating your right to use the AskAGuru Platform: (A) ONLY SONGS THAT HAVE BEEN LAWFULLY DISSEMINATED UNDER THE AUTHORITY OF THE COPYRIGHT OWNER MAY BE INCLUDED IN A MIX; (B) YOU WILL NOT PUBLISH THE TRACK LISTING FOR YOUR MIX ON ANY THIRD-PARTY WEBSITE AND LINK TO SUCH SITE FROM THE AskAGuru PLATFORM; (C) YOU WILL ABIDE BY THE TERMS OF SERVICE OF ANY 3rd PARTY, PROPRIETARY OR AFFILIATE STREAMING SOURCE UTILIZED BY THE ASKAGURU PLATFORM; 
</p>
<br></br>
<p>
12.4 You agree not to defame, harass, abuse, threaten, stalk or defraud Users of the AskAGuru Platform, or collect, or attempt to collect, personal information about Users or third parties without their consent.
</p>
<br></br>
<p>
12.5 You agree not to intentionally interfere with or damage, impair or disable the operation of the AskAGuru Platform or any User's enjoyment of it, by any means, including uploading or otherwise disseminating viruses, worms, spyware, adware or other malicious code.
</p>
<br></br>
<p>
12.6 You agree not to remove, circumvent, disable, damage or otherwise interfere with any security-related features of the AskAGuru Platform, features that prevent or restrict the use or copying of any content accessible through the AskAGuru Platform, or features that enforce limitations on the use of the AskAGuru Platform. The AskAGuru Platform contains programming limitations that are enforced through technical measures adopted by AskAGuru, and any efforts to disable or defeat those efforts for the purpose of violating any provisions of the statutory licenses set forth in Sections 112 and 114 of the U.S. Copyright Act, 17 U.S.C. §§ 112 and 114, is strictly prohibited.
</p>
<br></br>
<p>
12.7 You agree not to attempt to gain unauthorized access to the AskAGuru Platform, or any part of it, other accounts, computer systems or networks connected to the AskAGuru Platform, or any part of it, through hacking, password mining or any other means or interfere or attempt to interfere with the proper working of the AskAGuru Platform or any activities conducted on the AskAGuru Platform.
</p>
<br></br>
<p>
12.8 You agree not to obtain or attempt to obtain any materials or information through any means not intentionally made available through the AskAGuru Platform. You agree neither to modify the AskAGuru Platform in any manner or form, nor to use modified versions of the AskAGuru Platform, including, without limitation, for the purpose of obtaining unauthorized access to the AskAGuru Platform.
</p>
<br></br>
<p>
12.9 You agree that you will not use any robot, spider, scraper, or other automated means to access the AskAGuru Platform for any purpose without our express written permission or bypass our robot exclusion headers or other measures we may use to prevent or restrict access to the AskAGuru Platform.
</p>
<br></br>
<p>
12.10 You agree not to utilize framing techniques to enclose any trademark, logo, or other AskAGuru Materials without our express written consent. You agree not to use any meta tags or any other "hidden text" utilizing AskAGuru' name or trademarks without our express written consent.
</p>
<br></br>
<p>
12.11 You will promptly remove any links that AskAGuru finds objectionable in its sole discretion. You agree not to use any AskAGuru logos, graphics, or trademarks as part of the link without our express written consent.
</p>
<br></br>
<p>
12.12 You agree not to make unsolicited offers, advertisements, proposals, or send junk mail or spam to other Users of the AskAGuru Platform. This includes, but is not limited to, unsolicited advertising, promotional materials or other solicitation material, bulk mailing of commercial advertising, chain mail, informational announcements, charity requests, and petitions for signatures.
</p>
<br></br>
<p>
12.13 You agree not to reverse engineer, decompile, disassemble or otherwise attempt to discover the source code of the AskAGuru Platform or any part thereof, except and only to the extent that such activity is expressly permitted by applicable law notwithstanding this limitation.
</p>
<br></br>
<p>
12.14 You agree not to modify, adapt, translate or create derivative works based upon the AskAGuru Platform or any part thereof, except and only to the extent that such activity is expressly permitted by applicable law notwithstanding this limitation.
</p>
<br></br>
<p>
<b>13. Account Information </b>
</p>
<br></br>
<p>
In order to access some features of the AskAGuru Platform, you will have to create an account. You agree that the information you provide to AskAGuru upon registration and, at all other times, will be true, accurate, current, and complete. You also agree that you will ensure that this information is kept accurate and up-to-date at all times. You acknowledge, consent and agree that AskAGuru may access, preserve and disclose your account information and User Submission if required to do so by law or in a good faith belief that such access preservation or disclosure is reasonably necessary to: (1) comply with legal process; (2) enforce the Terms; (3) respond to a claim that any User Submission violates the rights of third parties; (4) respond to your requests for customer service; or (5) protect the rights, property or personal safety of AskAGuru, its users and the public.
</p>
<br></br>
<p>
<b>14. Password </b>
</p>
<br></br>
<p>
When you register you will be asked to provide a password. As you will be responsible for all activities that occur under your password, you should keep your password confidential. You are solely responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. If you have reason to believe that your account is no longer secure (for example, in the event of a loss, theft or unauthorized disclosure or use of your account ID or password), you will immediately notify AskAGuru. You may be liable for the losses incurred by AskAGuru or others due to any unauthorized use of your account.
</p>
<br></br>
<p>
<b>15. Dealings with Advertisers </b>
</p>
<br></br>
<p>
Your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the AskAGuru Platform are solely between you and such advertiser(s). YOU AGREE THAT AskAGuru WILL NOT BE RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGE OF ANY SORT INCURRED AS THE RESULT OF ANY SUCH DEALINGS OR AS THE RESULT OF THE PRESENCE OF SUCH ADVERTISERS ON THE AskAGuru Platform.
</p>
<br></br>
<p>
<b>16. Links and Third Party Content</b>
</p>
<br></br>
<p>
AskAGuru or third parties may provide links on the AskAGuru Platform to other sites or content ("Reference Sites"). AskAGuru has no control over such Reference Sites or content, and therefore makes no claim or representation regarding, and expressly disclaims responsibility for, the accuracy, quality, legality, nature, availability or reliability of Reference Sites or content linked to by the AskAGuru Platform. AskAGuru provides links to you only as a convenience, and the inclusion of any link on the AskAGuru Platform does not imply our affiliation, endorsement, or adoption of the linked site or any information therein. ACCESS AND USE OF REFERENCE SITES, INCLUDING THE INFORMATION, MATERIAL, PRODUCTS, AND SERVICES ON REFERENCE SITES OR AVAILABLE THROUGH REFERENCE SITES, IS SOLELY AT YOUR OWN RISK. When you leave the AskAGuru Platform, our Terms and Privacy policy no longer govern. You should review applicable terms and policies, including the privacy and data gathering practices, of any Reference Sites.
</p>
<br></br>
<p>
<b>17. Availability of Service</b>
</p>
<br></br>
<p>
AskAGuru may make changes to or discontinue any of the media, web communities, products, or services available within the AskAGuru Platform at any time, and without notice. You agree that any changes to or discontinuation of the AskAGuru Platform by AskAGuru will be without liability to you, even if such changes or discontinuation results in your loss of access to or use of User Submissions, AskAGuru Materials or any other Content. The media, products, or services on the AskAGuru Platform may be out of date, and AskAGuru makes no commitment to update these materials on the AskAGuru Platform.
</p>
<br></br>
<p>
<b>18. User Disagreements</b>
</p>
<br></br>
<p>
You are solely responsible for your involvement with other Users.
</p>
<br></br>
<p>
<b>19. Terms of Use Violations; Termination</b>
</p>
<br></br>
<p>
19.1 AskAGuru. You agree that AskAGuru, in its sole discretion and for any or no reason, may terminate any User or customer account (or any part thereof) you may have on the AskAGuru Platform or your use of the AskAGuru Platform, and remove and discard all or any part of your account or any User Submission, at any time, with or without notice. You agree that any termination of your access to the AskAGuru Platform or any account you may have or portion thereof may be effected without prior notice, and you agree that AskAGuru shall not be liable to you or any third-party for any such termination. AskAGuru does not permit infringing activities, including any violations of the conditions set forth in Sections 112 and 114 of the United States Copyright Act, on the AskAGuru Platform, and reserves the right to terminate access to the AskAGuru Platform, and remove all content submitted, by any persons who are found to be infringers. Any suspected fraudulent, abusive, or illegal activity may be referred to appropriate law enforcement authorities. These remedies are in addition to any other remedies AskAGuru may have at law or in equity.
</p>
<br></br>
<p>
19.2 User. If you are dissatisfied with the AskAGuru Platform, please let us know by electronic mailing us at contact@AskAGuru.com. Your input is valuable to us. Your only other remedy with respect to any dissatisfaction with (a) the AskAGuru Platform, (b) any term of these Terms, (c) any policy or practice of AskAGuru in operating the AskAGuru Platform, or (d) any content or information transmitted through the AskAGuru Platform, is to terminate these Terms and your account. You may terminate these Terms at any time by closing your account, discontinuing your use of any and all parts of the AskAGuru Platform, and providing AskAGuru notice of termination by e-mailing us contact@AskAGuru.com.
</p>
<br></br>
<p>
<b>20. Indemnification; Hold Harmless</b>
</p>
<br></br>
<p>
You agree to indemnify and hold harmless AskAGuru, and its parent, subsidiaries, affiliates or any related companies (including those which share substantially common ownership), its suppliers, licensors and partners, and the officers, directors, employees, agents and representatives of any of them from any and all claims, losses, obligations, damages, liabilities, costs or debt and expenses (including attorney's fees) arising out of: (1) your use or misuse of the AskAGuru Platform; (2) your User Submissions, including AskAGuru' use, display or other exercise of its license rights granted herein with respect to your User Submissions; (3) your violation of these Terms; (4) your violation of the rights of any other person or entity, including claims that any User Submission infringes or violates any third party intellectual property rights; and (5) your breach of the foregoing representations, warranties, and covenants. AskAGuru reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of AskAGuru. AskAGuru will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.
</p>
<br></br>
<p>
<b>21. Disclaimers; No Warranties</b>
</p>
<br></br>
<p>
21.1 No warranties. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, AskAGuru, AND ITS AFFILIATES, PARTNERS, AND SUPPLIERS DISCLAIM ALL WARRANTIES, STATUTORY, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM AskAGuru OR THROUGH THE AskAGuru Platform WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN. YOU EXPRESSLY ACKNOWLEDGE THAT AS USED IN THIS SECTION 21, THE TERM AskAGuru INCLUDES AskAGuru' OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS, AGENTS, SUPPLIERS, LICENSORS, AFFILIATES AND SUBCONTRACTORS.
</p>
<br></br>
<p>
21.2 "As is" and "As available" and "With All Faults". YOU EXPRESSLY AGREE THAT THE USE OF THE AskAGuru Platform IS AT YOUR SOLE RISK. THE AskAGuru Platform, USER SUBMISSIONS AND ANY THIRD-PARTY MEDIA, CONTENT, SOFTWARE, SERVICES OR APPLICATIONS MADE AVAILABLE IN CONJUNCTION WITH OR THROUGH THE AskAGuru Platform ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE", "WITH ALL FAULTS" BASIS AND WITHOUT WARRANTIES OR REPRESENTATIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED.
</p>
<br></br>
<p>
21.3 Platform Operation and Content. AskAGuru, ITS SUPPLIERS, LICENSORS, AFFILIATES, AND PARTNERS DO NOT WARRANT THAT THE AskAGuru MATERIALS, USER SUBMISSIONS, AskAGuru Platform, OR ANY OTHER INFORMATION OFFERED ON OR THROUGH THE AskAGuru Platform OR ANY REFERENCE SITES WILL BE UNINTERRUPTED, OR FREE OF ERRORS, VIRUSES OR OTHER HARMFUL COMPONENTS, AND DO NOT WARRANT THAT ANY OF THE FOREGOING WILL BE CORRECTED.
</p>
<br></br>
<p>
21.4 Accuracy. AskAGuru, ITS SUPPLIERS, LICENSORS, AFFILIATES, AND PARTNERS DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE AskAGuru Platform OR ANY REFERENCE SITES IN TERMS OF CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE.
</p>
<br></br>
<p>
21.5 Harm to Your Computer. YOU UNDERSTAND AND AGREE THAT YOU USE, ACCESS, DOWNLOAD, OR OTHERWISE OBTAIN INFORMATION, MATERIALS, OR DATA THROUGH THE AskAGuru Platform (INCLUDING RSS FEEDS) OR ANY REFERENCE SITES AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM) OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF SUCH MATERIAL OR DATA.
</p>
<br></br>
<p>
22. Limitation of Liability and Damages
</p>
<br></br>
<p>
22.1 Limitation of Liability. UNDER NO CIRCUMSTANCES, AND UNDER NO LEGAL THEORY, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL AskAGuru OR ITS AFFILIATES, CONTRACTORS, DIRECTORS, EMPLOYEES, AGENTS, OR THIRD PARTY PARTNERS OR SUPPLIERS, BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES (INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA OR USE OR COST OF COVER) ARISING OUT OF OR RELATING TO THESE TERMS OR THAT RESULT FROM YOUR USE OR THE INABILITY TO USE THE AskAGuru MATERIALS AND USER SUBMISSIONS ON THE AskAGuru Platform OR ANY REFERENCE SITES, THE AskAGuru Platform ITSELF, OR ANY OTHER INTERACTIONS WITH AskAGuru, EVEN IF AskAGuru OR A AskAGuru AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES, AskAGuru' LIABILITY WILL BE LIMITED TO THE EXTENT PERMITTED BY LAW.
</p>
<br></br>
<p>
22.2 Limitation of Damages. IN NO EVENT SHALL AskAGuru OR ITS AFFILIATES, CONTRACTORS, EMPLOYEES, AGENTS, OR THIRD PARTY PARTNERS, LICENSORS OR SUPPLIERS' TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR USE OF THE AskAGuru Platform (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), WARRANTY, OR OTHERWISE) EXCEED THE GREATER OF THE AMOUNT OF (A) ONE HUNDRED DOLLARS OR (B) THE AMOUNT OF FEES YOU HAVE PAID AskAGuru IN THE 12 MONTHS PRIOR TO THE ACTION GIVING RISE TO THE LIABILITY.
</p>
<br></br>
<p>
22.3 Reference Sites. THESE LIMITATIONS SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY REASON OF ANY PRODUCTS OR SERVICES SOLD OR PROVIDED ON ANY REFERENCE SITES OR OTHERWISE BY THIRD PARTIES OTHER THAN AskAGuru AND RECEIVED BY YOU THROUGH OR ADVERTISED ON THE AskAGuru Platform OR RECEIVED BY YOU THROUGH ANY REFERENCE SITES.
</p>
<br></br>
<p>
23. Limitations by Applicable Law; Basis of the Bargain
</p>
<br></br>
<p>
23.1 Limitations by Applicable Law. CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF YOU RESIDE IN SUCH A JURISDICTION, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS. THE LIMITATIONS OR EXCLUSIONS OF WARRANTIES, REMEDIES OR LIABILITY CONTAINED IN THESE TERMS APPLY TO YOU TO THE FULLEST EXTENT SUCH LIMITATIONS OR EXCLUSIONS ARE PERMITTED UNDER THE LAWS OF THE JURISDICTION IN WHICH YOU ARE LOCATED.
</p>
<br></br>
<p>
23.2 Basis of the Bargain. YOU ACKNOWLEDGE AND AGREE THAT AskAGuru HAS OFFERED ITS PRODUCTS AND SERVICES, SET ITS PRICES(if/when applicable) AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN, THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND AskAGuru, AND THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN YOU AND AskAGuru. YOU ACKNOWLEDGE AND AGREE THAT AskAGuru WOULD NOT BE ABLE TO PROVIDE THE AskAGuru Platform TO YOU ON AN ECONOMICALLY REASONABLE BASIS WITHOUT THESE LIMITATIONS.
</p>
<br></br>
<p>
24. Music; Digital Millennium Copyright Act
</p>
<br></br>
<p>
(a) AskAGuru at the moment utilizes a 3rd party streaming API courtesy of Apple Music. At this nascent stage this is the only viable way we can provide you with our minimum viable product but in the future we may explore other avenues to benefit our audience and musicians. These future plans we foresee will entail paying royalties to ASCAP, BMI and SESAC for the public performance of musical works. 
Please consult your legal counsel or see 17 U.S.C. § 512 if you feel that we are infringing any rights.
</p>
<br></br>
<p>
(b) AskAGuru does not permit infringement of intellectual property rights on the AskAGuru Platform, and AskAGuru will remove all Content and User Submissions if properly notified that such Content or User Submission infringes on another's intellectual property rights. It is AskAGuru' policy to respond promptly to notices of alleged infringement that comply with the terms of the Apple MusicKit API. In addition, AskAGuru will promptly terminate without notice the accounts of Users that are determined by AskAGuru to be "repeat infringers." A repeat infringer is a User who has been notified of infringing activity more than twice and/or has had a User Submission removed from the Website more than twice.
</p>
<br></br>
<p>
(c) Designated Agent Contact Information. AskAGuru' designated agent for notices of claimed infringement can be contacted at:
Via E-mail: contact@AskAGuru.com
</p>
<br></br>
<p>
(d) Counter Notification. If you receive a notification from AskAGuru that material made available by you on or through the Service has been the subject of a Notification of Claimed Infringement, then you will have the right to provide AskAGuru with what is called a "Counter Notification." To be effective, a Counter Notification must be in writing, provided to AskAGuru' Designated Agent through one of the methods identified in paragraph (c), and include substantially the following information:
A physical or electronic signature of the subscriber;
Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled;
A statement under penalty of perjury that the subscriber has a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled; and
The subscriber's name, address, and telephone number, and a statement that the subscriber consents to the jurisdiction of Federal District Court for the judicial district in which the address is located, or if the subscriber's address is outside of the United States, for any judicial district in which AskAGuru may be found, and that the subscriber will accept service of process from the person who provided notification under Section 17.3 above or an agent of such person.
A party submitting a Counter Notification should consult a lawyer or see 17 U.S.C. § 512 to confirm the party's obligations to provide a valid counter notification under the Copyright Act.
</p>
<br></br>
<p>
(e) False Notifications of Claimed Infringement or Counter Notifications. The Copyright Act provides that:
</p>
<br></br>
<p>
[a]ny person who knowingly materially misrepresents under [Section 512 of the Copyright Act (17 U.S.C. § 512)] (1) that material or activity is infringing, or (2) that material or activity was removed or disabled by mistake or misidentification, shall be liable for any damages, including costs and attorneys' fees, incurred by the alleged infringer, by any copyright owner or copyright owner's authorized licensee, or by a service provider, who is injured by such misrepresentation, as the result of [AskAGuru] relying upon such misrepresentation in removing or disabling access to the material or activity claimed to be infringing, or in replacing the removed material or ceasing to disable access to it.
17 U.S.C. § 512(f).
AskAGuru reserves the right to seek damages from any party that submits a notification of claimed infringement or counter notification in violation of the law.
For the avoidance of doubt, only notices submitted under the Digital Millennium Copyright Act or the terms of use stipulated by the Apple Musickit API team and the procedures set forth in this Section 17 should be sent to the Designated Agent at contact@AskAGuru.com. Any other comments, compliments, complaints or suggestions about AskAGuru, the operation of the Service or any other matter should be sent to contact@AskAGuru.com.
</p>
<br></br>
<p>
25. United States Export Controls
</p>
<br></br>
<p>
You agree to comply with all export laws and restrictions and regulations of the United States Department of Commerce or other United States or other sovereign agency or authority, and not to export, or allow the export or re-export of any technical data or any direct product thereof in violation of any such restrictions, laws or regulations, or unless and until all required licenses and authorizations are obtained with respect to the countries specified in the applicable United States Export Administration Regulations (or any successor supplement or regulations). The transfer of certain technical data and commodities may require a license from an agency of the United States government and/or written assurances by you that you will not export such technical data or commodities to certain foreign countries without prior approval of such agency. Your rights under these Terms are contingent on your compliance with this provision.
</p>
<br></br>
<p>
26. Miscellaneous
</p>
<br></br>
<p>
26.1 Notice. AskAGuru may provide you with notices, including those regarding changes to AskAGuru' terms and conditions, by postings on the AskAGuru Platform, via electronic mail, or by U.S. Mail. Notice sent via e-mail will be deemed given twenty-four hours after such e-mail is sent, unless AskAGuru is notified that the electronic mail address is invalid. Alternatively, we may give you legal notice by mail to a postal address, if provided by you through the AskAGuru Platform. In such case, notice will be deemed given three days after the date of mailing.
</p>
<br></br>
<p>
26.2 Dispute Resolution
</p>
<br></br>
<p>
(a) Mandatory Arbitration. Please read this carefully. It affects your rights. YOU AND AskAGuru AND EACH OF OUR RESPECTIVE SUBSIDIARIES, AFFILIATES, PREDECESSORS IN INTEREST, SUCCESSORS, AND PERMITTED ASSIGNS AGREE TO ARBITRATION (EXCEPT FOR MATTERS THAT MAY BE TAKEN TO SMALL CLAIMS COURT), AS THE EXCLUSIVE FORM OF DISPUTE RESOLUTION EXCEPT AS PROVIDED FOR BELOW, FOR ALL DISPUTES AND CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR USE OF THE SERVICE. Arbitration is more informal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, allows for more limited discovery than in court, and is subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award. Please visit www.adr.org for more information about arbitration.
</p>
<br></br>
<p>
(b) Commencing Arbitration. A party who intends to seek arbitration must first send to the other, by certified mail, a written notice of intent to arbitrate (a "Notice"), or, in the absence of a mailing address provided by you to AskAGuru, to you via any other method available to AskAGuru, including via e-mail. The Notice to AskAGuru should be addressed to: AskAguru Inc., Attn: Chief Executive Officer (the "Arbitration Notice Address"). The Notice must (i) describe the nature and basis of the claim or dispute; and (ii) set forth the specific relief sought (the "Demand"). If you and AskAGuru do not reach an agreement to resolve the claim within 30 days after the Notice is received, you or AskAGuru may commence an arbitration proceeding as set forth below or file a claim in small claims court. THE ARBITRATION SHALL BE ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION ("AAA") IN ACCORDANCE WITH ITS COMMERCIAL ARBITRATION RULES AND THE SUPPLEMENTARY PROCEDURES FOR CONSUMER RELATED DISPUTES (the "Rules"), AS MODIFIED BY THIS AGREEMENT. The Rules and AAA forms are available online at www.adr.org or by calling the AAA at 1-800-778-7879, or by writing to the Notice Address. If you are required to pay a filing fee to commence an arbitration against AskAGuru, then AskAGuru will promptly reimburse you for your confirmed payment of the filing fee upon AskAGuru' receipt of Notice at the Arbitration Notice Address that you have commenced arbitration along with a receipt evidencing payment of the filing fee, unless your Demand is equal to or greater than $1,000, in which case you are solely responsible for the payment of the filing fee.
</p>
<br></br>
<p>
(c) Arbitration Proceeding. The arbitration shall be conducted in the English language. A single independent and impartial arbitrator shall be appointed pursuant to the Rules, as modified herein. You and AskAGuru agree to comply with the following rules, which are intended to streamline the dispute resolution process and reduce the costs and burdens on the parties: (i) the arbitration shall be conducted by telephone, online and/or be solely based on written submissions, the specific manner to be chosen by the party initiating the arbitration; (ii) the arbitration shall not require any personal appearance by the parties or witnesses unless otherwise mutually agreed in writing by the parties; and (iii) any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.
</p>
<br></br>
<p>
(d) No Class Actions. YOU AND AskAGuru AGREE THAT YOU AND AskAGuru MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. FURTHER, YOU AGREE THAT THE ARBITRATOR MAY NOT CONSOLIDATE PROCEEDINGS OF MORE THAN ONE PERSON'S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING, AND THAT IF THIS SPECIFIC PROVISO IS FOUND TO BE UNENFORCEABLE, THEN THE ENTIRETY OF THIS MANDATORY ARBITRATION SECTION SHALL BE NULL AND VOID.
</p>
<br></br>
<p>
(e) Decision of the Arbitrator. Barring extraordinary circumstances, the arbitrator shall issue his or her decision within 120 days from the date the arbitrator is appointed. The arbitrator may extend this time limit for an additional 30 days in the interests of justice. All arbitration proceedings shall be closed to the public and confidential and all records relating thereto shall be permanently sealed, except as necessary to obtain court confirmation of the arbitration award. The award of the arbitrator shall be in writing and shall include a statement setting forth the reasons for the disposition of any claim. The arbitrator shall apply the laws of the State of California in conducting the arbitration. You acknowledge that these terms and your use of the Service evidence a transaction involving interstate commerce. The United States Federal Arbitration Act shall govern the interpretation, enforcement, and proceedings pursuant to the Mandatory Arbitration clause in these Terms.
</p>
<br></br>
<p>
(f) These Terms and your use of the Service shall be governed by the substantive laws of the State of California without reference to its choice or conflicts of law principles. Only if the Mandatory Arbitration clause is deemed to be null and void, then all disputes arising between you and AskAGuru under these Terms shall be subject to the exclusive jurisdiction of the state and federal courts located in Los Angeles County, California, and you and AskAGuru hereby submit to the personal jurisdiction and venue of these courts.
</p>
<br></br>
<p>
(g) Equitable Relief. The foregoing provisions of this Dispute Resolution section do not apply to any claim in which AskAGuru seeks equitable relief of any kind. You acknowledge that, in the event of a breach of these Terms by AskAGuru or any third party, the damage or harm, if any, caused to you will not entitle you to seek injunctive or other equitable relief against AskAGuru, including with respect to any User Submissions, and your only remedy shall be for monetary damages, subject to the limitations of liability set forth in these Terms.
</p>
<br></br>
<p>
(h) Claims. You and AskAGuru agree that, notwithstanding any other rights the party may have under law or equity, any cause of action arising out of or related to these Terms or the Service, excluding a claim for indemnification, must commence within one year after the cause of action accrues. Otherwise, such cause of action is permanently barred.
</p>
<br></br>
<p>
(i) Improperly Filed Claims. All claims you bring against AskAGuru must be resolved in accordance with this Dispute Resolution section.
All claims filed or brought contrary to this Dispute Resolution section shall be considered improperly filed. Should you file a claim contrary to this Dispute Resolution section, AskAGuru may recover attorneys' fees and costs up to $5,000, provided that AskAGuru has notified you in writing of the improperly filed claim, and you have failed to promptly withdraw the claim.
</p>
<br></br>
<p>
26.3 Waiver. A provision of these Terms may be waived only by a written instrument executed by the party entitled to the benefit of such provision. The failure of AskAGuru to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision.
</p>
<br></br>
<p>
26.4 Severability. If any provision of these Terms shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions.
</p>
<br></br>
<p>
26.5 Assignment. The Terms and related Guidelines, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by AskAGuru without restriction. Any assignment attempted to be made in violation of these Terms shall be void.
</p>
<br></br>
<p>
26.6 No Agency. You agree that no joint venture, partnership, employment, or agency relationship exists between you and AskAGuru as a result of these Terms or use of the AskAGuru Platform. You further acknowledge that by submitting User Submissions or other Content, no confidential, fiduciary, contractually implied or other relationship is created between you and AskAGuru other than pursuant to these Terms.
</p>
<br></br>
<p>
26.7 Survival. The Sections entitled "Ownership; Proprietary Rights", "Audio Content", "User Submissions", "Password", "Indemnification; Hold Harmless", "Disclaimers; No Warranties", "Limitation of Liability and Damages", "Limitations by Applicable Law; Basis of the Bargain", and "Miscellaneous" will survive any termination of these Terms.
</p>
<br></br>
<p>
26.8 Headings. The heading references herein are for convenience purposes only, do not constitute a part of these Terms, and shall not be deemed to limit or affect any of the provisions hereof.
</p>
<br></br>
<p>
26.9 Entire Agreement. This is the entire agreement between you and AskAGuru relating to the subject matter herein and supersedes all previous communications, representations, understandings and agreements, either oral or written, between the parties with respect to said subject matter. These Terms shall not be modified except in a writing, signed by both parties, or by a change to these Terms or Guidelines made by AskAGuru as set forth in Section 4 above.
</p>
<br></br>
<p>
26.10 Disclosures. The services hereunder are offered by AskAGuru Corp. contactable by electronic mail at contact@AskAGuru.com. If you are a California resident, you may have this same information electronically mailed to you by sending an email to the above mentioned email address with your electronic mail address and a request for this information.</p>
                </p>
                <br></br>
                <p>Enjoy AskAGuru!</p>
            </div>
        )
    }
}

export default Terms;
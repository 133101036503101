import React from 'react';
import styled from 'styled-components';
import './Privacy_Policy.css'

const Container = styled.div`
  background-color: #202020;
  color: #eb6734;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
`;

const Header = styled.h1`
  color: #eb6734;
  font-size: 2.5em;
`;

const Subheader = styled.h2`
  color: #eb6734;
  font-size: 2em;
`;

const Paragraph = styled.p`
  color: #eb6734;
  font-size: 1.2em;
`;

const PrivacyPolicy = () => {
  return (
    <div className='background_div'>
    <Container>
       
      <Header>Privacy Policy</Header>
      <br></br>

      <Subheader>Personal Information Collection</Subheader>
      <br></br>
      <Paragraph>
        When you register with our app, we collect personal information such as your name, email address, and account preferences. We also collect information about the music you listen to, such as your playlists, favorite artists, and songs you've played.
      </Paragraph>
      <br></br>
      <Subheader>Use of Personal Information</Subheader>
      <Paragraph>
        We use your personal information to personalize your experience, provide customer support, and send you updates and notifications related to our service. Your listening habits are used to generate recommendations and personalize your music discovery experience.
      </Paragraph>
      <br></br>
      <Subheader>Sharing of Personal Information</Subheader>
      <Paragraph>
        We do not sell your personal information to third parties. We may share your information with third-party service providers to provide the necessary services to us, such as email service providers, customer support providers, and analytics providers.
      </Paragraph>
      <br></br>
      <Subheader>Data Security</Subheader>
      <Paragraph>
        We take the security of your data very seriously. We implement various security measures to protect the security of your personal information. However, we cannot guarantee absolute security as no method of transmission or storage is 100% secure.
      </Paragraph>
      <br></br>
      <Subheader>Children's Privacy</Subheader>
      <Paragraph>
        Our service is not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we discover that a child under 13 has provided us with personal information, we will promptly delete it.
      </Paragraph>
      <br></br>
      <Subheader>Changes to This Policy</Subheader>
      <Paragraph>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page, and if the changes are significant, we will provide a more prominent notice.
      </Paragraph>
      <br></br>
      <Subheader>Contact Us</Subheader>
      <Paragraph>
        If you have any questions or concerns about this Privacy Policy, please contact us at contact@askaguru.com.
      </Paragraph>
    </Container>
    </div>
  );
};

export default PrivacyPolicy;

import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../App';
import './LoginModal.css';

const LoginModal = ({ onClose }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  
  const navigate = useNavigate();
  const { updateUserContext } = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');


  // handle login
  const handleLogin = async (e) => {
    e.preventDefault();
  
    try {
      const loginResponse = await fetch(`${API_URL}/login/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          username,
          password
        })
      });

      const tokenData = await loginResponse.json();

      if (loginResponse.ok) {
        onClose(); // Close the modal
        const dataResponse = await fetch(`${API_URL}/my_data`, {
          headers: {
            Authorization: `Bearer ${tokenData.access_token}`
          }
        });

        if (dataResponse.ok) {
          const userData = await dataResponse.json();
          console.log("USRDATA",userData)
          userData.user.access_token = tokenData.access_token;
          updateUserContext(userData);
          navigate('/profile');
        } else {
          console.log('Failed to retrieve user data');
        }
      } else {
        console.log('Login failed');
      }
    } catch (error) {
      console.log('An error occurred', error);
    }
  };

  // handle signup
  const handleSignUp = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      console.log('Passwords do not match');
      return;
    }

    try {
      const signUpResponse = await fetch(`${API_URL}/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          new_user: {
            email,
            password
          }
        })
      });

      ;

      if (signUpResponse.ok) {
        console.log('Sign up successful');
        const loginUpResponse = await fetch(`${API_URL}/login/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          username:email,
          password
        })
      });
        const tokenData = await loginUpResponse.json();

        if (loginUpResponse.ok){
          onClose(); // Close the modal
          const dataResponse = await fetch(`${API_URL}/my_data`, {
            headers: {
              Authorization: `Bearer ${tokenData.access_token}`
            }
          });

          if (dataResponse.ok) {
            const userData = await dataResponse.json();
            console.log("USRDATA",userData)
            userData.user.access_token = tokenData.access_token;
            updateUserContext(userData);
            navigate('/profile');
          } else {
            console.log('Failed to retrieve user data');
          }

        }

      } else {
        console.log('Sign up failed');
      }
    } catch (error) {
      console.log('An error occurred', error);
    }
  };

  const handleCancel = () => {
    onClose(); // Close the modal
  };

  const toggleForm = () => {
    setIsSignUp(!isSignUp);
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>{isSignUp ? 'Sign Up' : 'Login'}</h2>
        {isSignUp ? (
          <form onSubmit={handleSignUp}>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password:</label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="button-group">
              <button type="submit" className="sign-up-button">
                Sign Up
              </button>
              <button type="button" className="cancel-button" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </form>
        ) : (
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="button-group">
              <button type="submit" className="login-button">
                Signup/Login
              </button>
              <button type="button" className="cancel-button" onClick={handleCancel}>
                Cancel
              </button>
            </div>
          </form>
        )}
        <button type="button" className="toggle-form-button" onClick={toggleForm}>
          {isSignUp ? 'Go to Login' : 'Go to Sign Up'}
        </button>
      </div>
    </div>
  );
};

export default LoginModal;

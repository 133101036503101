import React, { createContext, useState } from 'react';

export const MusicKitContext = createContext();

export const MusicKitProvider = ({ children }) => {
  const [musicKitInstance, setMusicKitInstance] = useState(null);

  if (!musicKitInstance) {
    const musicKit = window.MusicKit.configure({
        developerToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiIsImtpZCI6IkQ4REgzM1BITEwifQ.eyJpc3MiOiIzM1M2UUJONVk2IiwiZXhwIjoxNzMyOTY5NzIzLCJpYXQiOjE3MTcyMDE3MjN9.FfnmfkS1uHKLCmgPfuJE5EoBSJfqu2ThMvZ9Y8Z114UM52EX7rVa79TQ87DWrQf3H0ga60ajlPXd5QFF2q9NiA',
        app: {
            name: 'AskAGuru Web App',
            build: '0.1'
        }
    });
    setMusicKitInstance(musicKit);
  }

  return (
    <MusicKitContext.Provider value={musicKitInstance}>
      {children}
    </MusicKitContext.Provider>
  );
};

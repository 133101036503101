import React, { useState, useEffect, useContext } from 'react';
import { MusicKitContext } from '../../core/MusicProvider';
import './landing.css'
import { UserContext } from '../../App';
import CreateRecommendationModal from "../CreateRecommendation";
import UserProfile from "../UserProfile";
import { Routes, Route, Link, useParams } from 'react-router-dom';
import unliked from '../../assets/animations/favorite.json';
import Lottie from 'lottie-react'
//import { useMusicPlayer } from '../../contexts/musicPlayerContext';

const PlaylistLandingPageComponent = () => {

  const API_URL = process.env.REACT_APP_API_URL;

  const { user, updateUserContext, playSong, updateArtworkUrl, setupAlbumUrlMusicPlayer  } = useContext(UserContext);
 
 
  console.log("user!!!", user)

  const [playlists, setPlaylists] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState('All');
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [searchQuery, setSearchQuery] = useState('');
  const [likedPlaylistsIds, setListLikedPlaylistsIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUsername, setSelectedUsername] = useState(null);
  const userContext = useContext(UserContext);
  const playlistsPerPage = 10;
  const display_url = process.env.REACT_APP_URL;
  console.log("display_url",display_url);

  const musicKit = useContext(MusicKitContext);
  console.log("musicKit",musicKit);
  
  

  useEffect(() => {
    // Fetch playlists from the API

    if (user){
      console.log("user.user.user_id!!!",user.user.user_id);
      console.log("user.user.user.user.access_token!!!",user.user.access_token);
      fetch(`${API_URL}/users/${user.user.user_id}/playlists/liked`, {
      method: 'GET', // The method is GET
      headers: {
          'Accept': 'application/json', // Setting the Accept header
          'Authorization': `Bearer ${user.user.access_token}` // Including the JWT in the Authorization header
      }
  })
  .then(response => {
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
  })
  .then(data => {
      console.log('Success:', data);
      setListLikedPlaylistsIds(data)
  })
  .catch(error => {
      console.error('Error:', error);
  });
  
  }
    
    fetch(`${API_URL}/playlists`)
      .then(response => response.json())
      .then(data => setPlaylists(data.sort(() => Math.random() - 0.5)));
  }, [user]);

  const handleGenreClick = genre => {
    setSelectedGenre(genre);
    setSearchQuery('');
    setCurrentPage(1);
  };

  const handleUserClick = (username) => {
    setSelectedUsername(username);
  };

  const handleSuggestClick = (playlist) => {
    console.log("suggest clicked",playlist);
    setSelectedPlaylist(playlist);
    setIsModalOpen(true);
  };

  const handleSearch = event => {
    setSearchQuery(event.target.value.toLowerCase());
    setCurrentPage(1);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); 
  };

  const handlePlayRec = (songId) => {
    console.log("play song", songId);
    musicKit.setQueue({
      song: songId,
    }).then(function () {
      musicKit.play();
    });
    playSong();
   
  };

  const handleSearchChange = event => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    if (query !== '') {
      setSelectedGenre('All');
    }
  };
  console.log(handleSearchChange)
  console.log(handleSearch)

  const handlePlaySong = (songId,recommendations,album_url) => {
    console.log("songId",songId);
    const songIds = [songId, ...recommendations.map(recommendation => recommendation.song_id)];
    musicKit.setQueue({
      songs: [songIds],
      startPlaying: true,
    }).then(function () {
      musicKit.play();
    });
    playSong();
    setupAlbumUrlMusicPlayer(album_url)
    
  };
  
 

  

  const handleToggleRecommendations = playlistId => {
    setPlaylists(prevPlaylists =>
      prevPlaylists.map(playlist => {
        if (playlist.playlist.playlist_id === playlistId) {
          return {
            ...playlist,
            showRecommendations: !playlist.showRecommendations,
          };
        }
        return playlist;
      })
    );
  };

  /*const formatDuration = duration => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };*/

  const formatDuration = (duration) => {
    const date = new Date(0);
    date.setSeconds(duration / 1000);
    let timeString = date.toISOString().substr(14, 5);
    // Remove leading zero from minutes
    timeString = timeString.startsWith('00:') ? timeString.slice(3) : (timeString.startsWith('0') ? timeString.slice(1) : timeString);
    return timeString;
  };

  const handleUnlikePlaylist = async (playlist_id) => {
    // Call your API endpoint to unlike the playlist
    console.log("unlike try");
    try {
      const requestOptions = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.user.access_token}`,
          'accept': 'application/json',
        },
      };
      
      const liked_plist_response = await fetch(`${API_URL}/playlists/${playlist_id}/like`,requestOptions);
      let data = await liked_plist_response.json()
      console.log("unlike try done");

      // Remove the playlist ID from the likedPlaylistsIds state
      setListLikedPlaylistsIds(likedPlaylistsIds.filter(likedPlaylist => likedPlaylist.playlist_id !== playlist_id));
    } catch (error) {
      console.error('Error unliking the playlist:', error);
    }
  };

   const handleLikePlaylist = async (playlist_id) => {

    console.log("playlist_id!!!!!!!",playlist_id)

    if (user == null){
      alert("You must be logged in to like a playlist");
    }
    else{
     //alert("Trying");
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.user.access_token}`,
          'accept': 'application/json',
        },
      };
      
      const liked_plist_response = await fetch(`${API_URL}/playlists/${playlist_id}/like`,requestOptions);
      let data = await liked_plist_response.json()
       console.log("liked_plist_response GTWIYBIW!!!", data)
       if (data["detail"] == "Playlist already liked"){
        alert("Playlist already liked")
       }
       else{
        setListLikedPlaylistsIds([...likedPlaylistsIds, { playlist_id: playlist_id }]);
        alert("Playlist was successfully liked!")
        
       }
    }

   

   }

  
 


  const truncate = (input) => input.length > 30 ? `${input.substring(0, 30)}...` : input;

  const indexOfLastPlaylist = currentPage * playlistsPerPage;
  const indexOfFirstPlaylist = indexOfLastPlaylist - playlistsPerPage;

  const filteredPlaylists = playlists.filter(playlist => {
    const isGenreMatch = selectedGenre === '' || playlist.playlist.genre === selectedGenre || selectedGenre === 'All';
    const isSearchMatch =
      !searchQuery ||
      playlist.playlist.artist_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      playlist.playlist.song_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (playlist.playlist.recommendations &&
        playlist.playlist.recommendations.some(recommendation =>
          recommendation.song_title.toLowerCase().includes(searchQuery.toLowerCase())
        ));
    return isGenreMatch && isSearchMatch;
  });

  const isPlaylistLiked = (playlistId) => {
    return likedPlaylistsIds.some(likedPlaylist => likedPlaylist.playlist_id === playlistId);
  };

  const currentPlaylists = filteredPlaylists.slice(indexOfFirstPlaylist, indexOfLastPlaylist);

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };
  
  return (
    
    <div className="component" style={{ display: 'flex' }}>
      <div className="genres" style={{ flex: 1, padding: '10px', backgroundColor: 'black' }}>
        <input className="search-bar"  type="text" placeholder="search" value={searchQuery} onChange={handleSearchChange} style={{ marginBottom: '10px' }} />
        <ul>
          <li className="genre" onClick={() => handleGenreClick('All')} style={{ color: selectedGenre === 'All' ? '#eb6734' : 'white' }}>
            All
          </li>
          <li className="genre" onClick={() => handleGenreClick('Afrobeat')} style={{ color: selectedGenre === 'Afrobeat' ? '#eb6734' : 'white' }}>
            Afrobeat
          </li>
          <li className="genre" onClick={() => handleGenreClick('Blues')} style={{ color: selectedGenre === 'Blues' ? '#eb6734' : 'white' }}>
            Blues
          </li>
          <li className="genre" onClick={() => handleGenreClick('Classical')} style={{ color: selectedGenre === 'Classical' ? '#eb6734' : 'white' }}>
            Classical
          </li>
          <li className="genre" onClick={() => handleGenreClick('Country')} style={{ color: selectedGenre === 'Country' ? '#eb6734' : 'white' }}>
            Country
          </li>
          <li className="genre" onClick={() => handleGenreClick('Electronic')} style={{ color: selectedGenre === 'Electronic' ? '#eb6734' : 'white' }}>
            Electronic
          </li>
          <li className="genre" onClick={() => handleGenreClick('Funk')} style={{ color: selectedGenre === 'Funk' ? '#eb6734' : 'white' }}>
            Funk
          </li>
          <li className="genre" onClick={() => handleGenreClick('Gospel')} style={{ color: selectedGenre === 'Gospel' ? '#eb6734' : 'white' }}>
            Gospel
          </li>
          <li className="genre" onClick={() => handleGenreClick('Hip-Hop')} style={{ color: selectedGenre === 'Hip-Hop' ? '#eb6734' : 'white' }}>
            Hip-Hop
          </li>
          <li className="genre" onClick={() => handleGenreClick('Instrumental')} style={{ color: selectedGenre === 'Instrumental' ? '#eb6734' : 'white' }}>
            Instrumental
          </li>
          <li className="genre" onClick={() => handleGenreClick('Jazz')} style={{ color: selectedGenre === 'Jazz' ? '#eb6734' : 'white' }}>
            Jazz
          </li>
          <li className="genre" onClick={() => handleGenreClick('K-Pop')} style={{ color: selectedGenre === 'K-Pop' ? '#eb6734' : 'white' }}>
            K-Pop
          </li>
          <li className="genre" onClick={() => handleGenreClick('Latin')} style={{ color: selectedGenre === 'Latin' ? '#eb6734' : 'white' }}>
            Latin
          </li>
          <li className="genre" onClick={() => handleGenreClick('Metal')} style={{ color: selectedGenre === 'Metal' ? '#eb6734' : 'white' }}>
            Metal
          </li>
          <li className="genre" onClick={() => handleGenreClick('Pop')} style={{ color: selectedGenre === 'Pop' ? '#eb6734' : 'white' }}>
            Pop
          </li>
          <li className="genre" onClick={() => handleGenreClick('Punk')} style={{ color: selectedGenre === 'Punk' ? '#eb6734' : 'white' }}>
            Punk
          </li>
          <li className="genre" onClick={() => handleGenreClick('Reggae')} style={{ color: selectedGenre === 'Reggae' ? '#eb6734' : 'white' }}>
            Reggae
          </li>
          <li className="genre" onClick={() => handleGenreClick('RnB')} style={{ color: selectedGenre === 'RnB' ? '#eb6734' : 'white' }}>
            RnB
          </li>
          <li className="genre" onClick={() => handleGenreClick('Rock')} style={{ color: selectedGenre === 'Rock' ? '#eb6734' : 'white' }}>
            Rock
          </li>
          <li className="genre" onClick={() => handleGenreClick('Soul')} style={{ color: selectedGenre === 'Soul' ? '#eb6734' : 'white' }}>
            Soul
          </li>    
        </ul>
      </div>
      <div className="playlist-container" style={{ flex: 3, padding: '10px', backgroundColor: 'black', color: 'white' }}>
        <h2>{selectedGenre === 'All' ? 'All Playlists' : `${selectedGenre} Playlists`}</h2>
        <div className="playlists">
          {currentPlaylists.map(playlist => (
            <div className="playlist" key={playlist.playlist.playlist_id}  style={{ marginBottom: '20px' }}>
              <img onClick={() => handlePlaySong(playlist.playlist.song_id, playlist.playlist.recommendations, playlist.playlist.artwork )} src={playlist.playlist.artwork} alt="Playlist Artwork" className="playlist-artwork"  />
              <div className="playlist-details" style={{ marginTop: '10px' }}>
                <p className="song-title">Song title: <a href={`${display_url}/playlist/`+playlist.playlist.playlist_id} target="_blank" rel="noopener noreferrer">{truncate(playlist.playlist.song_title)}</a></p>
                <p className="artist-info">Artist: {truncate(playlist.playlist.artist_name)}</p>
                <p className="genre-info">Genre: {playlist.playlist.genre}</p>
                <p className="genre-info" >Curated by: <Link onClick={() => handleUserClick(playlist.playlist.username)} to={`/user/${playlist.playlist.username}`}>{playlist.playlist.username}</Link></p>
                {isPlaylistLiked(playlist.playlist.playlist_id) ? (
    <button onClick={() => handleUnlikePlaylist(playlist.playlist.playlist_id)}>
      Unlike
    </button>
  ) : (
    <div className="unliked" onClick={() => handleLikePlaylist(playlist.playlist.playlist_id)} style={{width:"75px"}}><Lottie animationData={unliked}/></div>
  )}
                
                <p className="number-of-recommendations">recommendation count: {playlist.playlist.recommendations.filter(recommendation => recommendation.accepted).length}</p>
                {userContext.user !== null && (
                <button className="suggest-bottom-button" onClick={() => handleSuggestClick(playlist)}>
                  Contribute to Playlist
                </button>
              )}
                <button className="show-recommendations-button" onClick={() => handleToggleRecommendations(playlist.playlist.playlist_id)}>view recommendations</button>
                {playlist.showRecommendations && (
                  <div className="recommendations" style={{ marginTop: '10px' }}>
                    {playlist.playlist.recommendations.map(recommendation => {
                      if (recommendation.accepted) {
                        return (
                          <div className="recommendation" key={recommendation.recommendation_id} style={{ marginBottom: '10px' }}>
                            <span>
                              {recommendation.song_title} - {recommendation.artist_name}
                            </span>
                            <span style={{ marginLeft: '10px' }}>Duration: {formatDuration(recommendation.song_duration)}</span>
                            <button onClick={() => handlePlayRec(recommendation.song_id  )} style={{ marginLeft: '10px' }}>Play</button>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                )}
                {selectedUsername && <UserProfile username={selectedUsername} />}
              </div>
            </div>
          ))}
        </div>
        <div className="pagination" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          {Array(Math.ceil(filteredPlaylists.length / playlistsPerPage))
            .fill()
            .map((_, index) => (
              <button key={index} onClick={() => paginate(index + 1)} style={{ margin: '0 5px' }}>
                {index + 1}
              </button>
            ))}
        </div>
        
      </div>
      
      {isModalOpen && selectedPlaylist && <CreateRecommendationModal onClose={handleCloseModal} playlist_id={selectedPlaylist.playlist.playlist_id}  />}
    </div>
  );
};

export default PlaylistLandingPageComponent;

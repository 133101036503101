// MyComponent.js
import React, { useEffect, useState } from 'react';
import './MyComponent.css';
import gold_crown from '../../assets/animations/crown.json';
import silver_crown from '../../assets/animations/silver-crown.json';
import bronze_crown from '../../assets/animations/bronze-crown.json';
import royal_announcement from '../../assets/animations/royal-announcement.json';
import Lottie from 'lottie-react'
//import goldCrown from './gold-crown.png';
//import silverCrown from './silver-crown.png';
//import bronzeCrown from './bronze-crown.png';
//import placeholderImage from './placeholder-image.png';

const MyComponent = () => {
  const API_URL = process.env.REACT_APP_API_URL;


  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(15);

  async function fetchUsers() {
    try {
      const response = await fetch(`${API_URL}/rankings`);
      const data = await response.json();
      console.log(data)
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }

  function renderCrown(index) {
    if (index === 0) {
      return <div style={{width:"100px"}}><Lottie animationData={gold_crown}/></div>;
    } else if (index === 1) {
      return <div style={{width:"80px"}}><Lottie animationData={silver_crown}/></div>;
    } else if (index === 2) {
      return <div style={{width:"60px"}}><Lottie animationData={bronze_crown}/></div>;
    } else {
      return null;
    }
  }

  


  useEffect(() => {
    fetchUsers();
  }, []);

  const totalPages = Math.ceil(users.length / usersPerPage);

  // Generate an array of page numbers to render in the pagination
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  // Limit the clickable page numbers based on the available number of users
  const limitedPageNumbers = pageNumbers.slice(0, Math.min(totalPages, pageNumbers.length));

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);
  // Change the current page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  let copy_url = API_URL
  let PIC_API_URL  =  copy_url
  console.log("PIC_API_URL",PIC_API_URL)

  function createProfilePic(relative_url) {

    if (API_URL == "http://164.90.152.121:8002"){
      let url = "http://164.90.152.121:8002"
      let new_url = url += relative_url
      console.log("DEV",url)
    return url
    }
    else{

      let url = "https://askaguru.com/api"
      let new_url = url += relative_url
      console.log("PROD",url)
      return url

    }
  }

  

  return (
    <div className="user-rankings">
      <h1>Guru Rankings</h1>
      <center><div style={{width:"200px"}}><Lottie animationData={royal_announcement}/></div></center>
      {currentUsers.map((user, index) => (
        <div key={user.username} className="user">
          {/* Render the crown based on user's position */}
          {renderCrown(indexOfFirstUser + index)}
          {user.profile_pic ? (
        <img className="profile-photo" src={createProfilePic(user.profile_pic)}  />
      ) : (
        <img className="profile-photo" src='https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80' />
      )}
          <span>{user.username}</span>
          <span className="listens">Listens: {user.total_listens}</span>
        </div>
      ))}

      {/* Pagination */}
      <ul className="pagination">
        {limitedPageNumbers.map((pageNumber) => (
          <li key={pageNumber} className="page-item">
            <button
              className={`page-link ${currentPage === pageNumber ? 'active' : ''}`}
              onClick={() => paginate(pageNumber)}
            >
              {pageNumber}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MyComponent;






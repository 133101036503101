import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/asvg.svg';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { UserContext } from '../../App';
import LoginModal from '../LoginModal';
import CreatePlaylistModal from '../CreatePlaylistModal';

import Lottie from 'lottie-react'

const Header = () => {
  const { user, updateUserContext } = useContext(UserContext);
  const navigate = useNavigate();
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isPlaylistModalOpen, setPlaylistModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const ismobile = window.innerWidth <= 768;
      setIsMobile(ismobile);
    };
    window.addEventListener("resize", handleResize, false);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  const handleLogout = () => {
    updateUserContext(null);
    navigate('/');
  };

  const handleLoginModalOpen = () => {
    setLoginModalOpen(true);
  };

  const handleLoginModalClose = () => {
    setLoginModalOpen(false);
  };

  const handleCreatePlaylist = () => {
    if (user) {
      setPlaylistModalOpen(true);
    } else {
      handleLoginModalOpen();
    }
  };

  const handlePlaylistModalClose = () => {
    setPlaylistModalOpen(false);
  };

  return (
    isMobile ? 
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="navbar-logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className='nav-left'>
      <button 
        className="navbar-toggler" 
        type="button" 
        data-toggle="collapse" 
        data-target="#navbarNav" 
        aria-controls="navbarNav" 
        aria-expanded={!isNavCollapsed ? true : false}
        aria-label="Toggle navigation"
        onClick={() => setIsNavCollapsed(!isNavCollapsed)}>
        <span className="navbar-toggler-icon"></span>
      </button>
      </div>

      <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
        <div>
          <Link to="/" className="styled-link">
            Explore
          </Link>
        </div>
        {user ? (
          <div>
            <Link to="/profile" className="styled-link">
              Profile
            </Link>
          </div>
        ) : (
          <div>
            <Link to="/about" className="styled-link">
              About
            </Link>
          </div>
        )}
        <div>
          <Link to="/rankings" className="styled-link">
            Rankings
          </Link>
        </div>
        <div>
          <i className="fa fa-info-circle" aria-hidden="true" onClick={() => alert('Icon clicked!')}></i>
        </div>
        {user ? (
          <>
            <div className="sidemargin">
              <button className="orange-create" onClick={handleCreatePlaylist}>
                Create a Playlist
              </button>
            </div>
            <div className="loginmargin">
              <button className="login_button" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="loginmargin">
              <button className="login_button" onClick={handleLoginModalOpen}>
                Login
              </button>
            </div>
            <div className="sidemargin">
              <button className="orange-create" onClick={handleCreatePlaylist}>
                Create a Playlist
              </button>
            </div>
          </>
        )}
      </div>
      {isLoginModalOpen && <LoginModal onClose={handleLoginModalClose} />}
      {isPlaylistModalOpen && <CreatePlaylistModal onClose={handlePlaylistModalClose} />}
    </nav>
    :
    <nav className="navbar">
      <ul></ul>
      <div>
        <Link to="/" className="styled-link">
          Explore
        </Link>
      </div>
      {user ? (
        <div>
          <Link to="/profile" className="styled-link">
            Profile
          </Link>
        </div>
      ) : (
        <div>
          <Link to="/about" className="styled-link">
            About
          </Link>
        </div>
      )}
      <div>
        <Link to="/rankings" className="styled-link">
          Rankings
        </Link>
      </div>
      <div className="navbar-logo">
        <img src={logo} alt="Logo" />
      </div>
      {user ? (
        <>
          <div className="sidemargin">
            <button className="orange-create" onClick={handleCreatePlaylist}>
              Create a Playlist
            </button>
          </div>
          <div className="loginmargin">
            <button className="login_button" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="loginmargin">
            <button className="login_button" onClick={handleLoginModalOpen}>
              Login
              </button>
          </div>
          <div className="sidemargin">
            <button className="orange-create" onClick={handleCreatePlaylist}>
              Create a Playlist
            </button>
          </div>
        </>
      )}

      {isLoginModalOpen && <LoginModal onClose={handleLoginModalClose} />}
      {isPlaylistModalOpen && <CreatePlaylistModal onClose={handlePlaylistModalClose} />}
    </nav>
  );
};

export default Header;

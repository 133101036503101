import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../App';
import './PlaylistModal.css';

const CreatePlaylistModal = ({ onClose }) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedSong, setSelectedSong] = useState('');
  const [selectedSongId, setSelectedSongId] = useState('');
  const [selectedGenre, setSelectedGenre] = useState('');
  const user = useContext(UserContext);
  console.log("USERINIIT",user);

  const genres = [
    'Afrobeat',
    'Blues',
    'Classical',
    'Country',
    'Electronic',
    'Funk',
    'Gospel',
    'Hip-Hop',
    'Instrumental',
    'Jazz',
    'K-Pop',
    'Latin',
    'Metal',
    'Pop',
    'Punk',
    'RnB',
    'Reggae',
    'Rock',
    'Soul', 
  ];

  useEffect(() => {
    if (searchQuery.length >= 2) {
      const requestURL = `${API_URL}/search?request=${encodeURIComponent(
        searchQuery
      )}`;
    
      fetch(requestURL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.results) {
            setSearchResults(data.results);
          }
        })
        .catch((error) => {
          console.error('Error fetching search results:', error);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSongSelect = (result) => {
    setSelectedSong(`${result.attributes.name} - ${result.attributes.artistName}`);
    console.log("selectedSong",selectedSong);
    setSelectedSongId(result.id);
  };

  const handleGenreSelect = (e) => {
    setSelectedGenre(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedSongId) {
      const playlistData = {
        new_playlist: {
          song_id: selectedSongId,
          genre: selectedGenre,
        },
      };

      const requestURL = `${API_URL}/playlists`;

      console.log("USER TOKEN",user.user.user.token);
      console.log("USER TOKEN",user.user.user.access_token);
      fetch(requestURL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.user.user.access_token}`,
        },
        body: JSON.stringify(playlistData),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Success:', data);
          alert('Playlist created successfully!');
          onClose();
        })
        .catch((error) => {
          console.error('Error creating playlist:', error);
          alert('Something went wrong. Please try again.');
        });
    } else {
      alert('Please select a song');
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Create a Playlist</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              id="search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="genre">Genre:</label>
            <select id="genre" onChange={handleGenreSelect}>
              {genres.map((genre, index) => (
                <option key={index} value={genre}>
                  {genre}
                </option>
              ))}
            </select>
          </div>
          <div className="search-results">
            {searchResults.length > 0 ? (
              <ul>
                {searchResults.slice(0, 5).map((result, index) => (
                  <li
                    key={index}
                    onClick={() => handleSongSelect(result)}
                    className={selectedSongId === result.id ? 'selected' : ''}
                  >
                    {`${result.attributes.name} - ${result.attributes.artistName}`}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No search results</p>
            )}
          </div>
          <div className="button-group">
            <button type="submit">Create</button>
            <button type="button" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreatePlaylistModal;

// UserProfile.js
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import background from '../../assets/profilebgaag.png';
import { UserContext } from '../../App';
import '../ProfilePage/ProfilePage.css';
import CreateRecommendationModal from "../CreateRecommendation";
import { MusicKitContext } from '../../core/MusicProvider';


const profile = {
  avatar:
    'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
  backgroundImage:
  background,
}

const UserProfile = () => {

  // INIT DATA
  const API_URL = process.env.REACT_APP_API_URL;
  const user = useContext(UserContext);
  const musicKit = useContext(MusicKitContext);
  const { username } = useParams();
  //const token = null
  console.log("user?.user?.access_token ATTT",user?.user?.user?.access_token)
  const token = user?.user?.user?.access_token ?? null;
  const playlistsPerPage = 5;
  //

// STATE 
  const [selectedGenre, setSelectedGenre] = useState('All');
  const [currentSection, setCurrentSection] = useState('my_playlists');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [usersIFollow, setUserIFollow] = useState([]);
  const [amFollowing, setAmFollowing] = useState(false);
  const [openPlaylistId, setOpenPlaylistId] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const [usersLiked, setUsersLiked] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [userData, setUserData] = useState(null);
  const [playlists, setPlaylists] = useState([]);
  // STATE


  useEffect(() => {
    // Fetch user data based on the username from the API
    const fetchUserData = async () => {
        try {
         // Replace with your actual bearer token
         console.log("username", username);
         const url = `${API_URL}/user/${username}`
         console.log("initial query by username to get uid needed for lookup", url);
         const response = await fetch(url);
         console.log("get user data response by username", response);
         const data = await response.json();
         console.log("response by getting data", data);
         console.log("user_id", data["user_id"]);
         const user_id = data["user_id"];
         console.log("user_id derived from username lookup url", user_id);
         const my_data = await fetch(`${API_URL}/users/${user_id}`);
         const follow_data = await fetch(`${API_URL}/${user_id}/followers`);
         console.log("mydata gotten by user_id!!!",my_data)
         console.log("in there user_id!!!",user_id)
         console.log("follow_data gotten by user_id!!!",follow_data)
         const users_data = await my_data.json()
         const follow_info = await follow_data.json()
         console.log("users_data response!!!",users_data)
         console.log("follow_info!!! response",follow_info)
         const updateFollowers = () => {
          const followerIds = follow_info.follower_data.followers.map(follow => follow.followed_id);
          console.log("mapped followerIds",followerIds);
          setUserIFollow(followerIds);
          };
        updateFollowers();
        setUserData(users_data['user'])
        setPlaylists(users_data['playlists'])
      }catch(error){
        console.error('Error fetching recommendations:', error);
      }
    };
    fetchUserData();
  },[username]);

  

  const renderFollowButton = (page_owner_uid, loggedInUser) => {
    if (!loggedInUser || !loggedInUser.user || !loggedInUser.user.user || loggedInUser.user.user.user_id === userData.user_id) {
      return;  // Return null or a placeholder if loggedInUser is not available
    }

    const isFollowing = usersIFollow.includes(loggedInUser.user.user.user_id);

    return (
      <button onClick={() => isFollowing ? handleUnfollow(page_owner_uid,loggedInUser) : handleFollow(page_owner_uid,loggedInUser)}>
        {isFollowing ? 'Unfollow' : 'Follow'}
      </button>
    );
  };


  const handleFollow = async (userId,loggedInUser) => {
    // Implement follow logic
    //alert(token);
    console.log('Following user:', userId);
    // Update the state to include the new followed user
    if (user === null){
      //alert("must be logged in to follow");
    }
      //(token);
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'accept': 'application/json',
        },
      };
      try {
        const follow_response = await fetch(`${API_URL}/${userId}/follow`,requestOptions);
      let data = await follow_response.json()
      console.log("FOLLOWED DATA",data)
      if (data == 200){
        alert("success");
        setUserIFollow([...usersIFollow, loggedInUser.user.user.user_id]);
        
      }
      else{
        //alert("failed to follow user");
      }
        } catch (e) {
        console.log('Error')
        //alert("already liked`1");
        }
      
    
    
    
  };

  const handleUnfollow = async (userId,loggedInUser) => {
    // Implement unfollow logic
    //alert(token)
    console.log('Unfollowing user:', userId);
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'accept': 'application/json',
      },
    };

    try {
      const delete_response = await fetch(`${API_URL}/users/${userId}/follow`,requestOptions);
    let data = await delete_response.json()
    console.log("FOLLOWED DATA",data)
    if (data == 200){
      alert("success");
      setUserIFollow(usersIFollow.filter(id => id !== loggedInUser.user.user.user_id));
    }
    else{
      alert("failed to unfollow user");
    }
      } catch (e) {
      console.log('Error')
      //alert("already liked`1");
      }
    
    // Update the state to remove the unfollowed user
    
  };

  const sectionPlaylists = playlists[currentSection];
  if (!sectionPlaylists) {
    return <div>No playlists found for this section.</div>;
  }

  const indexOfLastPlaylist = currentPage * playlistsPerPage;
  const indexOfFirstPlaylist = indexOfLastPlaylist - playlistsPerPage;
  //const currentPlaylists = sectionPlaylists.slice(indexOfFirstPlaylist, indexOfLastPlaylist);

  const filteredPlaylists = sectionPlaylists.filter(playlist => {
    const isGenreMatch = selectedGenre === '' || playlist.playlist.genre === selectedGenre || selectedGenre === 'All';
    const isSearchMatch =
      !searchQuery ||
      playlist.playlist.artist_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      playlist.playlist.song_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (playlist.playlist.recommendations &&
        playlist.playlist.recommendations.some(recommendation =>
          recommendation.song_title.toLowerCase().includes(searchQuery.toLowerCase())
        ));
    return isGenreMatch && isSearchMatch;
  });

  const currentPlaylists = filteredPlaylists.slice(indexOfFirstPlaylist, indexOfLastPlaylist);

  const renderPlaylists = () => {
    if (!currentPlaylists || currentPlaylists.length === 0) {
      return <p>No playlists found.</p>;
    }

    const handleToggleRecommendations = (playlistId) => {
      setPlaylists((prevPlaylists) => {
        const updatedPlaylists = { ...prevPlaylists };
    
        // Close previously open recommendations
        Object.values(updatedPlaylists).forEach((section) => {
          section.forEach((playlist) => {
            playlist.showRecommendations = false;
          });
        });

        // Open selected playlist's recommendations
        const playlist = updatedPlaylists[currentSection].find(
          (playlist) => playlist.playlist.playlist_id === playlistId
        );
        
        if (playlist) {
          playlist.showRecommendations = true;
        }
    
        return updatedPlaylists;
      });
    };

    const handlePlaySong = (songId,recommendations) => {
      console.log("songId",songId);
      const songIds = [songId, ...recommendations.map(recommendation => recommendation.song_id)];
      musicKit.setQueue({
        songs: [songIds],
        startPlaying: true,
      }).then(function () {
        musicKit.play();
      });
    };

    const handlePlayRec = (songId) => {
      console.log("songId", songId);
      musicKit.setQueue({
        song: songId,
      }).then(function () {
        musicKit.play();
      });
    };

    const formatDuration = (duration) => {
      const date = new Date(0);
      date.setSeconds(duration / 1000);
      let timeString = date.toISOString().substr(14, 5);
      // Remove leading zero from minutes
      timeString = timeString.startsWith('00:') ? timeString.slice(3) : (timeString.startsWith('0') ? timeString.slice(1) : timeString);
      return timeString;
    };
    return (
      <>
       
        <ul className="playlists-list">
          {currentPlaylists.map((playlist) => (
            <li key={playlist.playlist.playlist_id} className="playlist-item">
              <p>Song Title: {playlist.playlist.song_title}</p>
              <p>Artwork: <img onClick={() => handlePlaySong(playlist.playlist.song_id, playlist.playlist.recommendations )} src={playlist.playlist.artwork} alt="Playlist Artwork" /></p>
              <p>Artist Name: {playlist.playlist.artist_name}</p>
              
                <button onClick={() => handleSuggestClick(playlist)}>Suggest a song</button>
              {/* Add more playlist information here */}
              <p className="number-of-recommendations">recommendation count: {playlist.playlist.recommendations.filter(recommendation => recommendation.accepted).length}</p>
                <button className="show-recommendations-button" onClick={() => handleToggleRecommendations(playlist.playlist.playlist_id)}>view recommendations</button>
                {playlist.showRecommendations && (
  <div className="recommendations" style={{ marginTop: '10px' }}>
    {playlist.playlist.recommendations
      .filter((recommendation) => recommendation.accepted)
      .map((recommendation) => (
        <div
          className="recommendation"
          key={recommendation.recommendation_id}
          style={{ marginBottom: '10px' }}
        >
          <span>
            {recommendation.song_title} - {recommendation.artist_name}
          </span>
          <span style={{ marginLeft: '10px' }}>
            Duration: {formatDuration(recommendation.song_duration)}
          </span>
          <button onClick={() => handlePlayRec(recommendation.song_id  )}  style={{ marginLeft: '10px' }}>Play</button>
          
        </div>
      ))}
  </div>
)}

            </li>
          ))}
        </ul>
      </>
    );
  };
  // Display user data once fetched

  const handleSectionClick = (section) => {
    setCurrentSection(section);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleGenreClick = genre => {
    setSelectedGenre(genre);
    setSearchQuery('');
    setCurrentPage(1);
  };

  const handleSuggestClick = (playlist) => {
    setSelectedPlaylist(playlist);
    setIsModalOpen(true);
  };
  const handleSearchChange = event => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    if (query !== '') {
      setSelectedGenre('All');
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); 
  };

  const pageNumbers = Math.ceil(sectionPlaylists.length / playlistsPerPage);

  console.log("APIURL!!!",API_URL)
  let copy_url = API_URL
  let photo_url = copy_url;
  console.log("user profile",userData.profile_pic)
  console.log("user DATATATATTAAT",userData)
  let official_photo_url = photo_url += userData.profile_pic;
  console.log("official_photo_url!!!",official_photo_url)

  return (
    
    <>
    
  <div>
      <div>
        <img className="h-32 w-full object-cover lg:h-48" src={profile.backgroundImage} alt="" />
      </div>
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div className="flex">
          {userData.profile_pic ? (
        <img className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" src={official_photo_url}  />
      ) : (
        <img className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" src={profile.avatar} />
      )
      
      }
        
          </div>
          
          
          <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="mt-6 min-w-0 flex-1 sm:hidden md:block">
              <h1 className="truncate text-2xl font-bold text-gray-900">{userData.username}</h1>
            </div>
            <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
              <button
                type="button"
                className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <p>Total Listens: {userData.total_listens}</p>
              </button>
              <button
                type="button"
                className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                 <p>Follower Count: {userData.follower_data.follower_count}</p>
              </button>
              <button
                type="button"
                className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                 <p>Following Count: {userData.follower_data.following_count}</p>
              </button>
            </div>
            
          </div>
        </div>
        
        {userData && renderFollowButton(userData.user_id,user)}
        <div className="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
          <h1 className="truncate text-2xl font-bold text-gray-900">{userData.username}</h1>
        </div>
      </div>
      <div>
        <br></br>
            <center><span><p>About Me: {userData.biography}</p></span></center>
          </div>
    </div>
    
    <div className="profile-page">
    
      <h3>{userData.username}'s Playlists</h3>
      <br></br>
     <center><input className="search-bar"  type="text" placeholder="search" value={searchQuery} onChange={handleSearchChange} style={{ marginBottom: '10px' }} /></center> 
     <br></br>
      <div className="section-buttons">
        <button
          className={currentSection === 'my_playlists' ? 'active' : ''}
          onClick={() => handleSectionClick('my_playlists')}
        >
          My Playlists
        </button>
        <button
          className={currentSection === 'liked_playlists' ? 'active' : ''}
          onClick={() => handleSectionClick('liked_playlists')}
        >
          Liked Playlists
        </button>
        <button
          className={currentSection === 'contributions' ? 'active' : ''}
          onClick={() => handleSectionClick('contributions')}
        >
          Contributions
        </button>
      </div>
      {renderPlaylists()}
      

      {sectionPlaylists.length > playlistsPerPage && (
        <div className="pagination">
          {Array.from({ length: pageNumbers }).map((_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={currentPage === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
      {isModalOpen && selectedPlaylist && <CreateRecommendationModal onClose={handleCloseModal} playlist_id={selectedPlaylist.playlist.playlist_id}  />}
    </div>
    </>
  );

  ///
  return (
    <div>
      <h2>User Profile for {username}</h2>
      {userData ? (
        <div>
          <p>Username: {userData.username}</p>
          {/* Add other user-specific information here */}
        </div>
      ) : (
        <p>Loading user data...</p>
      )}
    </div>
  );
};

export default UserProfile;

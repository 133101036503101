import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../App';
import './ProfilePage.css';
import RecommendationsComponent from '../RecommendationsComponent';
import CreateRecommendationModal from "../CreateRecommendation";
import { EnvelopeIcon, PhoneIcon, HeartIcon } from '@heroicons/react/20/solid'
import { MusicKitContext } from '../../core/MusicProvider';
import background from '../../assets/profilebgaag.png';

const profile = {
  name: 'Ricardo Cooper',
  email: 'ricardo.cooper@example.com',
  avatar:
    'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
  backgroundImage:
  background,
  fields: [
    ['Phone', '(555) 123-4567'],
    ['Email', 'ricardocooper@example.com'],
    ['Title', 'Senior Front-End Developer'],
    ['Team', 'Product Development'],
    ['Location', 'San Francisco'],
    ['Sits', 'Oasis, 4th floor'],
    ['Salary', '$145,000'],
    ['Birthday', 'June 8, 1990'],
  ],
}

const ProfilePage = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const user = useContext(UserContext);
  const [selectedGenre, setSelectedGenre] = useState('All');
  const [currentSection, setCurrentSection] = useState('my_playlists');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const playlistsPerPage = 5;
  const token = user.user.user.access_token;
  const [playlists, setPlaylists] = useState(user.user.playlists);
  const [openPlaylistId, setOpenPlaylistId] = useState(null);
  const musicKit = useContext(MusicKitContext);
  const [recommendations, setRecommendations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  console.log("musicKit",musicKit);
  
  useEffect(() => {
    setPlaylists(user.user.playlists);
  }, [user.user.playlists]);
  // Ensure we have the user data we need.
  if (!user || !user.user || !user.user.user || !user.user.playlists) {
    return <div>Data not found.</div>;
  }

  //const playlists = user.user.playlists;
  

  const sectionPlaylists = playlists[currentSection];
  if (!sectionPlaylists) {
    return <div>No playlists found for this section.</div>;
  }

  const indexOfLastPlaylist = currentPage * playlistsPerPage;
  const indexOfFirstPlaylist = indexOfLastPlaylist - playlistsPerPage;
  //const currentPlaylists = sectionPlaylists.slice(indexOfFirstPlaylist, indexOfLastPlaylist);

  const filteredPlaylists = sectionPlaylists.filter(playlist => {
    const isGenreMatch = selectedGenre === '' || playlist.playlist.genre === selectedGenre || selectedGenre === 'All';
    const isSearchMatch =
      !searchQuery ||
      playlist.playlist.artist_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      playlist.playlist.song_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (playlist.playlist.recommendations &&
        playlist.playlist.recommendations.some(recommendation =>
          recommendation.song_title.toLowerCase().includes(searchQuery.toLowerCase())
        ));
    return isGenreMatch && isSearchMatch;
  });

  

  const currentPlaylists = filteredPlaylists.slice(indexOfFirstPlaylist, indexOfLastPlaylist);

  

  const renderPlaylists = () => {
    if (!currentPlaylists || currentPlaylists.length === 0) {
      return <p>No playlists found.</p>;
    }

    

    const handleToggleRecommendations = (playlistId) => {
      setPlaylists((prevPlaylists) => {
        const updatedPlaylists = { ...prevPlaylists };
    
        // Close previously open recommendations
        Object.values(updatedPlaylists).forEach((section) => {
          section.forEach((playlist) => {
            playlist.showRecommendations = false;
          });
        });
    
        // Open selected playlist's recommendations
        const playlist = updatedPlaylists[currentSection].find(
          (playlist) => playlist.playlist.playlist_id === playlistId
        );
        
        if (playlist) {
          playlist.showRecommendations = true;
        }
    
        return updatedPlaylists;
      });
    };
    
    
    

    const handlePlaySong = (songId,recommendations) => {
      console.log("songId",songId);
      const songIds = [songId, ...recommendations.map(recommendation => recommendation.song_id)];
      musicKit.setQueue({
        songs: [songIds],
        startPlaying: true,
      }).then(function () {
        musicKit.play();
      });
    };

    const handlePlayRec = (songId) => {
      console.log("songId", songId);
      musicKit.setQueue({
        song: songId,
      }).then(function () {
        musicKit.play();
      });
    };

    const formatDuration = (duration) => {
      const date = new Date(0);
      date.setSeconds(duration / 1000);
      let timeString = date.toISOString().substr(14, 5);
      // Remove leading zero from minutes
      timeString = timeString.startsWith('00:') ? timeString.slice(3) : (timeString.startsWith('0') ? timeString.slice(1) : timeString);
      return timeString;
    };

    

    

    const deleteRecommendation = async (recommendation_id) => {
      const userConfirmation = window.confirm("Are you sure you want to delete this recommendation?");
      if(userConfirmation) {
        try {
          const token = user.user.user.access_token; // Replace with your actual bearer token
  
        console.log("DELETE OTKEN",token)
        console.log(" deleteRecommendation",recommendation_id)
  
        const response = await fetch(`${API_URL}/${recommendation_id}/`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
            'accept': 'application/json'
          }
        });
  
        if (response.ok) {
          // Recommendation rejected, perform necessary actions
          console.log('Recommendation deleted');
          // Remove the recommendation from the state
          setRecommendations(recommendations.filter((rec) => rec.recommendation_id !== recommendation_id));
        } else {
          throw new Error('Failed to reject recommendation');
        }
      } catch (error) {
        console.error('Error rejecting recommendation:', error);
      }
      }
      
    };

    

    const handleDeletePlaylist = (playlist_id) => {
      const userConfirmation = window.confirm("Are you sure you want to delete this playlist?");
    if(userConfirmation) {
      const url = `${API_URL}/playlists/${playlist_id}/`;

      fetch(url, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(json => {
        // Handle your JSON response here
        console.log(json);
        setPlaylists(playlists => {
          const updatedPlaylists = {...playlists};
          updatedPlaylists[currentSection] = updatedPlaylists[currentSection].filter(playlist => playlist.playlist.playlist_id !== playlist_id);
          return updatedPlaylists;
        });
        console.log(json);
      })
      .catch(e => {
        console.error('There was a problem with the fetch operation: ' + e.message);
      });
    }
    }

    

    return (
      <>
        <RecommendationsComponent />
        <ul className="playlists-list">
          {currentPlaylists.map((playlist) => (
            <li key={playlist.playlist.playlist_id} className="playlist-item">
              <p>Song Title: {playlist.playlist.song_title}</p>
              <p>Artwork: <img onClick={() => handlePlaySong(playlist.playlist.song_id, playlist.playlist.recommendations )} src={playlist.playlist.artwork} alt="Playlist Artwork" /></p>
              <p>Artist Name: {playlist.playlist.artist_name}</p>
              <button onClick={() => handleDeletePlaylist(playlist.playlist.playlist_id)}   className="delete-button">
                <i className="fas fa-trash-alt"></i>
                </button>
                <button onClick={() => handleSuggestClick(playlist)}>Suggest a song</button>
              {/* Add more playlist information here */}
              <p className="number-of-recommendations">recommendation count: {playlist.playlist.recommendations.filter(recommendation => recommendation.accepted).length}</p>
                <button className="show-recommendations-button" onClick={() => handleToggleRecommendations(playlist.playlist.playlist_id)}>view recommendations</button>
                {playlist.showRecommendations && (
  <div className="recommendations" style={{ marginTop: '10px' }}>
    {playlist.playlist.recommendations
      .filter((recommendation) => recommendation.accepted)
      .map((recommendation) => (
        <div
          className="recommendation"
          key={recommendation.recommendation_id}
          style={{ marginBottom: '10px' }}
        >
          <span>
            {recommendation.song_title} - {recommendation.artist_name}
          </span>
          <span style={{ marginLeft: '10px' }}>
            Duration: {formatDuration(recommendation.song_duration)}
          </span>
          <button onClick={() => handlePlayRec(recommendation.song_id  )}  style={{ marginLeft: '10px' }}>Play</button>
          <button onClick={() => deleteRecommendation(recommendation.recommendation_id)}   className="delete-button">
                <i className="fas fa-trash-alt"></i>
                </button>
        </div>
      ))}
  </div>
)}

            </li>
          ))}
        </ul>
      </>
    );
  };

  const handleSectionClick = (section) => {
    setCurrentSection(section);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleGenreClick = genre => {
    setSelectedGenre(genre);
    setSearchQuery('');
    setCurrentPage(1);
  };

  const handleSuggestClick = (playlist) => {
    setSelectedPlaylist(playlist);
    setIsModalOpen(true);
  };
  const handleSearchChange = event => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    if (query !== '') {
      setSelectedGenre('All');
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); 
  };

  const pageNumbers = Math.ceil(sectionPlaylists.length / playlistsPerPage);

  console.log("APIURL!!!",API_URL)
  let copy_url = API_URL
  let photo_url = copy_url;
  console.log("user profile",user.user.user.profile_pic)
  let official_photo_url = photo_url += user.user.user.profile_pic;
  console.log("official_photo_url!!!",official_photo_url)
  return (
    <>
  <div>
      <div>
        <img className="h-32 w-full object-cover lg:h-48" src={profile.backgroundImage} alt="" />
      </div>
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div className="flex">
          {user.user.user.profile_pic ? (
        <img className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" src={official_photo_url}  />
      ) : (
        <img className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" src={profile.avatar} />
      )}
        
          </div>
          
          <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="mt-6 min-w-0 flex-1 sm:hidden md:block">
              <h1 className="truncate text-2xl font-bold text-gray-900">{user.user.user.username}</h1>
            </div>
            <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
              <button
                type="button"
                className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <p>Total Listens: {user.user.user.total_listens}</p>
              </button>
              <button
                type="button"
                className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                 <p>Follower Count: {user.user.user.follower_data.follower_count}</p>
              </button>
              <button
                type="button"
                className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                 <p>Following Count: {user.user.user.follower_data.following_count}</p>
              </button>
            </div>
            
          </div>
        </div>
        <div className="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
          <h1 className="truncate text-2xl font-bold text-gray-900">{profile.name}</h1>
        </div>
      </div>
      <div>
        <br></br>
            <center><span><p>About Me: {user.user.user.biography}</p></span></center>
          </div>
    </div>
    
    <div className="profile-page">
    
      <h3>{user.user.user.username}'s Playlists</h3>
      <br></br>
     <center><input className="search-bar"  type="text" placeholder="search" value={searchQuery} onChange={handleSearchChange} style={{ marginBottom: '10px' }} /></center> 
     <br></br>
      <div className="section-buttons">
        <button
          className={currentSection === 'my_playlists' ? 'active' : ''}
          onClick={() => handleSectionClick('my_playlists')}
        >
          My Playlists
        </button>
        <button
          className={currentSection === 'liked_playlists' ? 'active' : ''}
          onClick={() => handleSectionClick('liked_playlists')}
        >
          Liked Playlists
        </button>
        <button
          className={currentSection === 'contributions' ? 'active' : ''}
          onClick={() => handleSectionClick('contributions')}
        >
          Contributions
        </button>
      </div>
      {renderPlaylists()}

      {sectionPlaylists.length > playlistsPerPage && (
        <div className="pagination">
          {Array.from({ length: pageNumbers }).map((_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={currentPage === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
      {isModalOpen && selectedPlaylist && <CreateRecommendationModal onClose={handleCloseModal} playlist_id={selectedPlaylist.playlist.playlist_id}  />}
    </div>
    </>
  );
};

export default ProfilePage;

import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../App';
import './RecommendationModal.css';

const CreateRecommendationModal = ({ onClose, playlist_id }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  console.log("PLAYLISTTT! ID",playlist_id);

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedSong, setSelectedSong] = useState('');
  const [selectedSongId, setSelectedSongId] = useState('');

  const user = useContext(UserContext);
  console.log("USERINIT", user);

  useEffect(() => {
    if (searchQuery.length >= 2) {
      const requestURL = `${API_URL}/search?request=${encodeURIComponent(
        searchQuery
      )}`

      fetch(requestURL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.results) {
            setSearchResults(data.results);
          }
        })
        .catch((error) => {
          console.error('Error fetching search results:', error);
        });
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSongSelect = (result) => {
    setSelectedSong(`${result.attributes.name} - ${result.attributes.artistName}`);
    console.log("selectedSong",selectedSong)
    setSelectedSongId(result.id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedSongId) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.user.user.access_token}`,
          'accept': 'application/json',
        },
        body: JSON.stringify({
          new_recommendations: [
            {
              song_id: selectedSongId,
            },
          ],
        }),
      };
      fetch(`${API_URL}/playlists/${playlist_id}`,requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log('Success:', data);
          alert('Recommendation created successfully!');
          onClose();
        })
        .catch((error) => {
          console.error('Error creating recommendation:', error);
          alert('Something went wrong. Please try again.');
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } else {
      alert('Please select a song');
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Suggest a song to this playlist:</h2>
        <br />
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="search">Search:</label>
            <input
              type="text"
              id="search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <div className="search-results">
            {searchResults.length > 0 ? (
              <ul>
                {searchResults.slice(0, 5).map((result, index) => (
                  <li
                    key={index}
                    onClick={() => handleSongSelect(result)}
                    className={selectedSongId === result.id ? 'selected' : ''}
                  >
                    {`${result.attributes.name} - ${result.attributes.artistName}`}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No search results</p>
            )}
          </div>
          <div className="button-group">
            <button type="submit">Create</button>
            <button type="button" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateRecommendationModal;

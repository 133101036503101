import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';


const ProfileByUUID = () => {

  const apiUrl = process.env.REACT_APP_API_URL;

  const { uuid } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Fetch the playlist from your API
    const fetchUser = async () => {
      try {
        const response = await fetch.get(`${apiUrl}/users/${uuid}`);
        console.log("THIDSS")
        console.log("UUID",uuid)
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
        // handle the error appropriately
      }
    };

    fetchUser();
  }, [uuid]);  // Re-fetch when the UUID changes

  if (!user) {
    return <div>User Profile</div>;
  }

  return (
    <div>
      <h1>User Profile</h1>
      {/* Render your playlist data here */}
    </div>
  );
};

export default ProfileByUUID;

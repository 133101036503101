import {React,createContext,useState, useEffect, useContext} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom"
import MyComponent from './components/MyComponent';
import PlaylistSlider from "./components/PlaylistSlider";
import OtherPage from './components/OtherPage';
import Header from './components/Header';
import ProfilePage from './components/ProfilePage';
import UserProfile from './components/UserProfile';
import ProfileByUUID from './components/ProfileByUUID';
import LoginModal from './components/LoginModal';
import PlaylistPage from './components/PlaylistPage';
import MusicPlayer from './components/MusicPlayer';
import Terms from './components/Terms';
import "./globals.css"
import "./styleguide.css"
import "./App.css";
import PrivacyPolicy from "./components/Privacy_Policy";
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import OneSignal from 'react-onesignal';

export const UserContext = createContext();

function App() {
  console.log("APP.JS FIRING");
  const [user, setUser] = useState(null);
  const [currentSong, setCurrentSong] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [currentPlayingImage, setCurrentPlayingImage] = useState("");
    const playSong = () => {
      //setCurrentSong(song); // Assuming song is passed to this function
      setIsVisible(true); // Show player when a song is played
      // Additional logic to play the song

    };
    const setupAlbumUrlMusicPlayer = (currentPlayingImage) => {
      //setCurrentSong(song); // Assuming song is passed to this function
      setCurrentPlayingImage(currentPlayingImage); // Show player when a song is played
      // Additional logic to play the song
     

    };
  
    const togglePlayer = () => setIsVisible(!isVisible);
    const closePlayer = () => setIsVisible(false);

   
  // Update the user context with the provided user object
  const updateUserContext = (userObj) => {
    setUser(userObj);
  };
    async function runOneSignal() {
    await OneSignal.init({ appId: 'YmQ1OTBmNWYtNmVlMi00YWEwLWIxMzktMjI2YzRmMjc5ZmU4', allowLocalhostAsSecureOrigin: true});
    OneSignal.showSlidedownPrompt();
  }
  useEffect(() => {
    runOneSignal();
  })

  return (
    <BrowserRouter>
    <UserContext.Provider value={{ user, updateUserContext,currentSong, playSong,setupAlbumUrlMusicPlayer,togglePlayer, closePlayer, isVisible,setupAlbumUrlMusicPlayer,setCurrentPlayingImage, currentPlayingImage }}>
    <div className="App">
      <Header/>
      {isVisible && <MusicPlayer />} {/* Place MusicPlayer here */}
    </div> 
    <Routes>
        <Route path="/" element={<h1><PlaylistSlider/></h1>} />
          <Route path="/rankings" element={<MyComponent />} />
          <Route path="/about" element={<OtherPage />} />
          <Route path="/user/:username" element={<UserProfile />} />
          <Route path="/users/:uuid" element={<ProfileByUUID />} />
          <Route path="/playlist/:uuid" element={<PlaylistPage />} />
          <Route path="/landing" />
          <Route path="/profile" element={<ProfilePage/>} />
          <Route path="/login" element={<LoginModal/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/terms-and-conditions" element={<Terms/>} />
          <Route path="/demo-landing" element={<Terms/>} /> 
          
      </Routes>
      
    </UserContext.Provider>
    </BrowserRouter>
  );
}

export default App;
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../App';
import './AboutSection.css';
import './Footer.css';
import fireworks2 from '../../assets/animations/fireworks2.json';
import partytime from '../../assets/animations/partytime.json';
import pixelglasses from '../../assets/animations/pixelglasses.json';
import facebook from '../../assets/animations/facebook.json';
import instagram from '../../assets/animations/instagram.json';
import twitter_logo from '../../assets/animations/twitter-logo.json';

import Lottie from 'lottie-react'

const OtherPage = () => {
  const navigate = useNavigate();
  const { user, updateUserContext } = useContext(UserContext);
  console.log(user);
  console.log(updateUserContext);
  const handlePrivacyPolicy =  () => {
    navigate('/privacy-policy');
  }

  const handleTerms =  () => {
    navigate('/terms-and-conditions');
  }

  const handleRedirect = () => {
    navigate('/'); // Replace '/other-page' with the path you want to navigate to
  };

  useEffect(() => {
    const login = async () => {
      // ...
    };

    login();
  }, [navigate]);

  return (
    <div>
      <section className="about-section">
        <h2 className="title">What is AskAGuru?</h2>
        <br />
        <center><div className="" style={{width:"150px"}}><Lottie animationData={pixelglasses}/></div></center>
        <p className="description">
          <span className="highlight">AskAGuru</span> is an app dedicated to helping music lovers discover and share their favorite songs with the world in the form of collaborative playlists! Ever wonder what it would be like to have the most epic playlist with songs that could have only come from the great sonic universe? We got you!
        </p>
        <br />
        <h5 className="title">Who are the Gurus?</h5>
        <br />
        <p className="description">
          <span className="highlight">You</span> are! Our platform relies on human peer-to-peer curation to create the most epic playlists! Do you like this playlist?! Well, it was curated by the amazing Gurus of our platform! Want to be one? Signup and start curating today!
        </p>
        <br />
        <h5 className="title">What are we planning next?</h5>
        <br />
        <p className="description">
          <span className="highlight">We</span> plan to continue to innovate and delight the world by making this the funnest space on the web! We're always thinking of building new features to enhance life for artists and music lovers, so join in on the fun and stay tuned!
        </p>
        <br />
        <p className="description">
          Be sure to follow us on our socials and find out what we're up to on our official <a href="https://askaguru.blog">blog</a>!
        </p>
        <br />
        <center><div style={{ display: 'flex', justifyContent: 'space-between', width: '200px' }}><a href="https://www.facebook.com/askagurucom" target="_blank" rel="noopener noreferrer">
      <Lottie animationData={facebook}/>
    </a>
    <a href="https://twitter.com/askaguruapp" target="_blank" rel="noopener noreferrer">
      <Lottie animationData={twitter_logo}/>
    </a>
    <a href="https://www.instagram.com/askaguruapp" target="_blank" rel="noopener noreferrer">
      <Lottie animationData={instagram}/>
    </a></div></center>
        <br />
        
        <br />
        <br />
        
        <button onClick={handleRedirect}>Back to Homepage</button>
        <button onClick={handlePrivacyPolicy}>View Our Privacy Policy</button>
        <button onClick={handleTerms}>View Our Terms & Conditions</button>
        <footer className="footer">
        </footer>
      </section>
    </div>
  );
};

export default OtherPage;

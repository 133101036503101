import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import './PlaylistPage.css';
import { MusicKitContext } from '../../core/MusicProvider';

const PlaylistPage = () => {

  const apiUrl = process.env.REACT_APP_API_URL;

  const musicKit = useContext(MusicKitContext);
  const { uuid } = useParams();
  const [playlist, setPlaylist] = useState(null);
  const [error, setError] = useState(null); // State for handling errors
  const [loading, setLoading] = useState(true); // State for handling loading status

  useEffect(() => {
    const fetchPlaylist = async () => {
      try {
        setError(null); // Resetting the error before a new API call
        setLoading(true); // Indicate loading process
        console.log("apiUrl",apiUrl);

        const response = await fetch(`${apiUrl}/playlists/${uuid}/`);
        if (!response.ok) {
          throw new Error(`An error occurred: ${response.statusText}`);
        }

        const data = await response.json(); // Parsing JSON data
        console.log("data",data);
        setPlaylist(data['playlist']); // Setting the playlist data into state
      } catch (error) {
        setError(error.toString()); // Handling error by setting state
      } finally {
        setLoading(false); // Indicate loading process has finished
      }
    };

    fetchPlaylist();
  }, [uuid]);

  const handlePlaySong = (songId,recommendations) => {
    console.log("songId",songId);
    const songIds = [songId, ...recommendations.map(recommendation => recommendation.song_id)];
    musicKit.setQueue({
      songs: [songIds],
      startPlaying: true,
    }).then(function () {
      musicKit.play();
    });
  };

  if (loading) {
    return <div>Loading...</div>; // Loading state
  }

  if (error) {
    return <div>Error: {error}</div>; // Error state
  }

  if (!playlist) {
    return <div>Error: Playlist not found!</div>; // If no data is received
  }

  // Rendering playlist information
  return (
    <div className="playlist-container">
      <div className="playlist-artwork">
        <img onClick={() => handlePlaySong(playlist.song_id, playlist.recommendations )} src={playlist.artwork} alt={`${playlist.artist_name} Artwork`} />
      </div>
      <button onClick={() => handlePlaySong(playlist.song_id, playlist.recommendations )}>Play Entire Playlist!</button>
      <div className="playlist-info">
        <h1 className="playlist-title">{playlist.song_title}</h1>
        <h2 className="playlist-artist">{playlist.artist_name}</h2>
        <p className="playlist-genre">{playlist.genre}</p>
        <p className="playlist-genre">curated with {'\u2764\uFE0F'} by: {playlist.username}</p>
        {/* Add any other information you want to display */}
      </div>
    </div>
  );
};

export default PlaylistPage;

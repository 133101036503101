import React, { useState, useEffect, useContext } from 'react';
import './explore.css'
import SlideshowComponent from '../BottomSlider'
import PlaylistLandingPageComponent from '../PlaylistLandingPageComponent'
import { MusicKitContext } from '../../core/MusicProvider';
import { EnvelopeIcon, PhoneIcon, HeartIcon, TrophyIcon } from '@heroicons/react/20/solid'
import kokopelli from '../../assets/animations/kokopelli.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import globe from '../../assets/animations/globe.json';
import heart1 from '../../assets/animations/heart1.json';
import heart2 from '../../assets/animations/heart2.json';
import heartbeats from '../../assets/animations/heartbeats.json';
import heartcircle from '../../assets/animations/heartcircle.json';
import heartnice from '../../assets/animations/heartnice.json';
import heartworld from '../../assets/animations/heartworld.json';
import LoginModal from '../LoginModal';
import CreatePlaylistModal from '../CreatePlaylistModal';
import Lottie from 'lottie-react'
import { UserContext } from '../../App';

function PlaylistSlider() {

  const API_URL = process.env.REACT_APP_API_URL;
  const { user, updateUserContext } = useContext(UserContext);
  
  const [playlists, setPlaylists] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isPlaylistModalOpen, setPlaylistModalOpen] = useState(false);

  const musicKit = useContext(MusicKitContext);
  console.log("musicKit",musicKit);

  // Fetch playlists data from the API endpoint and update the playlists state
  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        const response = await fetch(`${API_URL}/playlists`);
        const data = await response.json();
        console.log(data);
        setPlaylists(data);
      } catch (error) {
        console.error('Error fetching playlists:', error);
      }
    };

    fetchPlaylists();
  }, []);

  useEffect(() => {
    // Rotate the playlist every 3 seconds
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 5);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  // Get the current playlist to display in the slider
  const currentPlaylist = playlists.slice(0, 5)[currentIndex];

  const handlePlaySong = (songId) => {
    console.log("songId",songId);
    musicKit.setQueue({
      song: songId
    }).then(function () {
      musicKit.play();
    });
  };

  const handleLoginModalOpen = () => {
    setLoginModalOpen(true);
  };

  const handleLoginModalClose = () => {
    setLoginModalOpen(false);
  };

  const handleCreatePlaylist = () => {
    if (user) {
      setPlaylistModalOpen(true);
    } else {
      handleLoginModalOpen();
    }
  };

  const handlePlaylistModalClose = () => {
    setPlaylistModalOpen(false);
  };

  const truncate = (input) => input.length > 30 ? `${input.substring(0, 30)}...` : input;

  return (
    <div>
    <div className="playlist-slider">
      <div className="left-section">
        <h2>Create playlists together with other awesome humans!</h2>
        <br></br>
        <button onClick={handleCreatePlaylist} className="create-button">Create a playlist</button>
        <br></br>
        <br></br>
        <center><div className="globe" style={{width:"250px"}}><Lottie animationData={globe}/></div></center>
        <div className="heartniceleft" style={{width:"125px"}}><Lottie animationData={heartnice}/></div>
        <div className="heartniceright" style={{width:"125px"}}><Lottie animationData={heartnice}/></div>
        <div className="heartup" style={{width:"100px"}}><Lottie animationData={heart1}/></div>
        
 
        


        <div className="kokopelli" style={{width:"100px"}}><Lottie animationData={kokopelli}/></div>
       
        
        

      </div>
      <div className="right-section">
       
        <div className="slider">
          <div className="slide">
          <h2 ><center>Trending <i class="fas fa-crown"></i></center></h2>
            <img onClick={() => handlePlaySong(currentPlaylist?.playlist.song_id)} src={currentPlaylist?.playlist.artwork} alt="Album Art" />
            <p className="curated_by_tag"><center>curated by:</center></p>
            <p><center><div className="heartbeats" style={{width:"75px"}}><Lottie animationData={heartbeats}/></div><p className="username-trending">{currentPlaylist?.playlist.username}</p></center></p>
          </div>
        </div>
      </div>
      {isLoginModalOpen && <LoginModal onClose={handleLoginModalClose} />}
      {isPlaylistModalOpen && <CreatePlaylistModal onClose={handlePlaylistModalClose} />}
    </div>
     
     <SlideshowComponent/>
     <PlaylistLandingPageComponent/>
     </div>
  );
}

export default PlaylistSlider;

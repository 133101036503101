import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../App';

const RecommendationsComponent = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const user = useContext(UserContext);
  console.log("USEREHERERERERERE!  sd", user.user.user.user_id)
  //user_id = user.user.user.user_id
  const [recommendations, setRecommendations] = useState([]);

  useEffect(() => {
    const fetchRecommendations = async () => {
      try {
        const token = user.user.user.access_token; // Replace with your actual bearer token

        const response = await fetch(`${API_URL}/users/${user.user.user.user_id}/playlists/recommendations/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'accept': 'application/json'
          }
        });
        const data = await response.json();
        console.log("THEE data", data);
        setRecommendations(data);
      } catch (error) {
        console.error('Error fetching recommendations:', error);
      }
    };

    fetchRecommendations();
  }, [user.user.user.access_token]);

  const acceptRecommendation = async (recommendationId) => {
    try {
        const token = user.user.user.access_token; // Replace with your actual bearer token
        console.log(recommendationId);
        console.log(token);
      const response = await fetch(`${API_URL}/recommendations/${recommendationId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'accept': 'application/json'
        }
      });
      console.log("RESPONSE",response);
      if (response.ok) {
        // Recommendation accepted, perform necessary actions
        console.log('Recommendation accepted');
        // Remove the recommendation from the state
        setRecommendations(recommendations.filter((rec) => rec.recommendation_id !== recommendationId));
      } else {
        throw new Error('Failed to accept recommendation');
      }
    } catch (error) {
      console.error('Error accepting recommendation:', error);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };

  const rejectRecommendation = async (recommendationId) => {
    try {
        const token = user.user.user.access_token; // Replace with your actual bearer token

      console.log("DELETE OTKEN",token)
      console.log("DELETE id",recommendationId)

      const response = await fetch(`${API_URL}/recommendations/${recommendationId}/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'accept': 'application/json'
        }
      });

      if (response.ok) {
        // Recommendation rejected, perform necessary actions
        console.log('Recommendation rejected');
        // Remove the recommendation from the state
        setRecommendations(recommendations.filter((rec) => rec.recommendation_id !== recommendationId));
      } else {
        throw new Error('Failed to reject recommendation');
      }
    } catch (error) {
      console.error('Error rejecting recommendation:', error);
    }
  };

  return (
    <div>
      {recommendations.length > 0 ? (
        <div>
          <h3>Recommendations to Process</h3>
          <ul>
            {recommendations.map((recommendation) => (
              <li key={recommendation.recommendation_id}>
                <p>{recommendation.song_title} - {recommendation.artist_name}</p>
                <button
                  onClick={() => acceptRecommendation(recommendation.recommendation_id)}
                  style={{ backgroundColor: 'green' }}
                >
                  Accept Recommendation
                </button>
                <button
                  onClick={() => rejectRecommendation(recommendation.recommendation_id)}
                  style={{ backgroundColor: 'red' }}
                >
                  Reject Recommendation
                </button>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>No recommendations available</p>
      )}
    </div>
  );
};

export default RecommendationsComponent;

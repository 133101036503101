// MusicPlayer.js
import {React,createContext,useState, useEffect, useContext} from "react";
import { UserContext } from '../../App';
import './MusicPlayer.css';

const MusicPlayer = () => {
    const { currentSong, isVisible, togglePlayer, closePlayer, currentPlayingImage } = useContext(UserContext);
    if (!isVisible) return null; // Don't render if not visible

    return (
        <div className="music-player">
            {/* Display current song and player controls */}
            <img src={currentPlayingImage}></img>
            <button >Toggle</button>
            <button >Close</button>
        </div>
    );
};

export default MusicPlayer;
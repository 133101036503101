import React, { useContext, useEffect, useState } from "react";
import "./SlideshowComponent.css";
import { MusicKitContext } from "../../core/MusicProvider";
import CreateRecommendationModal from "../CreateRecommendation";
import { UserContext } from '../../App';


const SlideshowComponent = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  console.log("THIS IS THE URL", API_URL);

  const [landingData, setLandingData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredPlaylists, setFilteredPlaylists] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const musicKit = useContext(MusicKitContext);
  const userContext = useContext(UserContext);

  useEffect(() => {
    const fetchPlaylists = async () => {
      try {
        const response = await fetch(`${API_URL}/landing/`);
        console.log(response);
        const data = await response.json();
        console.log(data);
        setLandingData(data.landing_data);
        setFilteredPlaylists(data.landing_data.newest_playlists);
      } catch (error) {
        console.error('Error fetching playlists:', error);
      }
    };

    fetchPlaylists();
  }, []);

  const handleFilterClick = (filterType) => {
    let filteredData = [];

    if (filterType === "newest") {
      filteredData = landingData.newest_playlists;
    } else if (filterType === "mostPlays") {
      filteredData = landingData.most_plays;
    } else if (filterType === "longestPlaylists") {
      filteredData = landingData.longest_playlists;
    }

    setCurrentPage(1);
    setFilteredPlaylists(filteredData);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const truncate = (input) => input.length > 30 ? `${input.substring(0, 30)}...` : input;

  const handleNextClick = () => {
    const itemsPerPage = 5;
    const endIndex = (currentPage - 1) * itemsPerPage + itemsPerPage;
    if (endIndex < filteredPlaylists.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  /*const handlePlaySong = (songId) => {
    console.log("songId", songId);
    musicKit.setQueue({
      song: songId,
    }).then(function () {
      musicKit.play();
    });
  };*/

  const handlePlaySong = (songId,recommendations) => {
    console.log("songId",songId);
    const songIds = [songId, ...recommendations.map(recommendation => recommendation.song_id)];
    musicKit.setQueue({
      songs: [songIds],
      startPlaying: true,
    }).then(function () {
      musicKit.play();
    });
  };

  const handleSuggestClick = (playlist) => {
    setSelectedPlaylist(playlist);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); 
  };

  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <div className="slideshow-component">
      <div className="filter-buttons">
        <button onClick={() => handleFilterClick("newest")}>Newest</button>
        <button onClick={() => handleFilterClick("mostPlays")}>Most Plays</button>
        <button onClick={() => handleFilterClick("longestPlaylists")}>Longest Playlists</button>
      </div>
      <div className="slideshow">
        {filteredPlaylists.slice(startIndex, endIndex).map((playlist, index) => (
          <div className="slideshow-item" key={index}>
            <div className="slideshow-item-artwork">
              <img
                onClick={() => handlePlaySong(playlist.playlist.song_id, playlist.playlist.recommendations)}
                src={playlist.playlist.artwork}
                alt="Album Artwork"
              />
              {userContext.user !== null && (
                <div className="suggest-button" onClick={() => handleSuggestClick(playlist)}>
                  Suggest
                </div>
              )}
            </div>
            <div className="slideshow-item-details">
              <p className="song-name">{truncate(playlist.playlist.song_title)}</p>
              <p className="artist-name">{truncate(playlist.playlist.artist_name)}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="pagination">
        <button onClick={handlePrevClick} disabled={currentPage === 1}>
          Prev
        </button>
        <button
          onClick={handleNextClick}
          disabled={endIndex >= filteredPlaylists.length}
        >
          Next
        </button>
      </div>
      {isModalOpen && selectedPlaylist && <CreateRecommendationModal onClose={handleCloseModal} playlist_id={selectedPlaylist.playlist.playlist_id}  />}
    </div>
  );
};

export default SlideshowComponent;
